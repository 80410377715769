<template>
  <v-menu bottom offset-y transition="slide-y-transition" origin="center center" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon text v-on="on">
        <v-icon>mdi-dots-grid</v-icon>
      </v-btn>
    </template>

    <v-card>
      <app-menu></app-menu>
    </v-card>
  </v-menu>
</template>

<script>
  import { mapState } from 'vuex';
  import AppMenu from './AppMenu';

  // import menu from "./menu";

  export default {
    name: 'MenuPrincipalDropdown',
    
    components: { AppMenu },

    data() {
      return {
        // menus: menu,

        items: [
          { icon: "home", title: "Tela Inicial", to: "/" },
          { icon: "card_travel", title: "Catálogo", to: "/catalogo" },
          { icon: "shopping_cart", title: "Vendas", to: "/vendas" },
          { icon: "check_circle", title: "Compras", to: "/compras" },
          { icon: "monetization_on", title: "Financeiro", to: "/financeiro" },
          { icon: "assignment", title: "Relatórios", to: "/relatorios" }
        ],
        items2: [
          // { icon: "mdi-account-circle", href: "#", title: "Meu Perfil",
          //   click: e => {
          //     console.log(e);
          //   }
          // },
          // {
          //   icon: "settings", href: "#", title: "Configurações",
          //   click: e => {
          //     console.log(e);
          //   }
          // },
          { 
            icon: "mdi-exit-to-app", href: "#", title: "Sair",
            click: e => {
            this.$eventbus.$emit("APP_LOGOUT", 'Tô saindo!');
            }
          }
        ],
      }
    },

    methods: {
      name() {
        
      }
    },

    computed: {
      ...mapState([
        'nameOfState',
      ])
    },

  }
</script>

<style scoped>

</style>