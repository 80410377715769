import { defineStore } from 'pinia'

export const useEventsStore = defineStore('events', {
  state: () => ({
    alerta: false,
    teste: 'Isso é um teste'
  }),

  getters: {},

  actions: {

    alertar() {
      this.alerta = !this.alerta;
    },

    fecharAlerta() {
      this.alerta = false;
    },

    p() {
      console.log('Funciona');
    }
  },
})