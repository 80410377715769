import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function empresas() {
  return axios.get("listas/empresas").then((response) => response.data);
}

function parceiros() {
  return axios.get("listas/parceiros").then((response) => response.data);
}

function perfis() {
  return axios.get("listas/perfis").then((response) => response.data);
}

export default {
  empresas,
  parceiros,
  perfis,
};
