export default {
  user_id: null,
  guia_id: null,
  codigo_deposito_judicial: '',
  vencimento_boleto: null,
  data_recebimento_boleto: null,
  valor_transferencia: null,
  data_transferencia: 0,
  valor_a_pagar: null,
  guia: {
    empresa: {}
  }
}