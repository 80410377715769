import parceirosApi from "@/app/services/parceirosApi";
import parceiro from "@/app/store/models/parceiro";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    parceiro: {...parceiro},
    parceiros: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, parceiros) {
      state.parceiros = parceiros;
    },

    ABRIR(state, parceiro) {
      state.parceiro = parceiro;
    },

    ADICIONAR(state, parceiro) {
      state.parceiros.push(parceiro);
    },

    ATUALIZAR(state, parceiro) {
      let index = state.parceiros.findIndex(parceiro_ => parceiro_.id == parceiro.id);
      if (index > -1) {
        state.parceiros.splice(index, 1, parceiro);
      }
    },

    EXCLUIR(state, id) {
      let index = state.parceiros.findIndex(parceiro => parceiro.id == id);
      if (index > -1) {
        state.parceiros.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.parceiro = {...parceiro};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await parceirosApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let parceiro = await parceirosApi.abrir(id);
      commit('ABRIR', parceiro);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, parceiro) {
      let novoparceiro = await parceirosApi.salvar(parceiro);
      commit('ADICIONAR', novoparceiro);
    },

    async atualizar({ commit }, parceiro) {
      await parceirosApi.atualizar(parceiro);
      commit('ATUALIZAR', parceiro);
    },

    async excluir({ commit }, id) {
      await parceirosApi.excluir(id);
      commit('EXCLUIR', id);
    },

    buscarPecaPorCodigo({ commit }, codigo) {
      return parceirosApi.buscarCodigo(codigo);
    },

  }
}
