import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function percentuaisParceiros(filtro) {
  return axios.get('relatorios/percentuaisparceiros', {params: filtro})
    .then(response => response.data);
}

function guiasLancadas(filtro) {
  return axios.get('relatorios/guiaslancadas', {params: filtro})
    .then(response => response.data);
}

export default {
  percentuaisParceiros,
  guiasLancadas
}