<template>
  <v-text-field
    v-bind="attributes"
    v-model="dateFormatted"
    v-mask="'##/##/####'"
    ref="campo"
    :flat="isToBeFlat"
    :class="{ transparente: transparente && isToBeFlat }"
    :readonly="readonly || isSm"
    :disabled="disabled"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @keyup="menu = false;$emit('keyup', $event);"
    @keydown="$emit('keydown', $event)"
    @keypress.esc="dateFormatted = ''"
    @click:clear="clear"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        ref="menu"
        :left="left"
        :close-on-content-click="false"
        transition="scale-transition"
        origin="center center"
        offset-y
        max-width="290px"
        min-width="290px"
        nudge-bottom="20px"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-show="!readonly && !disabled"
            class="mr-2"
            @click="setYearPicker"
            >mdi-calendar</v-icon
          >
        </template>
        <v-date-picker
          v-model="date"
          ref="picker"
          locale="pt-BR"
          no-title
          :min="min"
          :max="max"
          @change="onChange"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: [String],
      default: '',
    },
    transparente: {
      type: Boolean,
      default: false,
    },
    raiseOnFocus: Boolean,
    flat: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    min: {
      type: String,
      default: "1930-01-01",
    },
    birthday: Boolean,
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    today: {
      type: Boolean,
      default: false
    }
  },

  data: (vm) => ({
    dateFormatted: '',
    menu: false,
    isFocused: false,
    max: null,
  }),

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },

    computedDateFormatted() {
      return this.dataBR(this.date);
    },

    date: {
      get() {
        this.dateFormatted = this.dataBR(this.value);
        return this.value;
      },
      set(val) {
        // this.dateFormatted = this.dataBR(val);
        this.$emit("input", val);
      },
    },

    isToBeFlat() {
      if (this.flat && this.raiseOnFocus && this.isFocused) {
        return false;
      } else {
        return this.flat;
      }
    },

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  mounted () {
    if (this.today && !this.value) {
      console.log('Escreva hoje');
    };
  },

  methods: {
    onInput() {
      if (this.dateFormatted) {
        // if (this.dateFormatted.length == 2) {
        //   this.dateFormatted = this.dateFormatted + "/" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "/" + (new Date().getFullYear()).toString();
        //   this.selecionarMes();
        // } 
        // else if (this.dateFormatted.length == 5) {
        //   this.dateFormatted = this.dateFormatted + "/" + new Date().getFullYear();
        //   this.selecionarAno();
        // }

        if (this.dateFormatted.length == 10) {
          this.$emit("input", this.dataUS(this.dateFormatted));
        }
      }
    },

    onFocus(e) {
      if (!this.isSm) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;
        this.isFocused = true;
      }
    },

    onBlur() {
      if (this.dateFormatted) {
        if (this.dateFormatted.length == 10) {
          this.date = this.dataUS(this.dateFormatted);
        } else if (
          this.dateFormatted.length > 0 &&
          this.dateFormatted.length < 10 &&
          this.date != ""
        ) {
          this.dateFormatted = this.dataBR(this.date);
        } else {
          this.date = this.dateFormatted = "";
        }
      } else {
        this.date = this.dateFormatted;
      }

      this.isFocused = false;
    },

    onChange() {
      this.$emit("change", this.date);
    },

    selecionarAno() {
      setTimeout(() => {
        this.$refs.campo.$el.querySelector("input").selectionStart = 6;
        this.$refs.campo.$el.querySelector("input").selectionEnd = 10;
      }, 0);
    },

    selecionarMes() {
      setTimeout(() => {
        this.$refs.campo.$el.querySelector("input").selectionStart = 3;
        this.$refs.campo.$el.querySelector("input").selectionEnd = 5;
      }, 0);
    },

    dataBR(date) {
      if (!date) return '';

      if (date.length > 10) {
        date = date.substr(0, 10);
      }

      const [year, month, day] = date.split(/[-/]/);
      return `${day}/${month}/${year}`;
    },

    dataUS(date) {
      if (!date) return '';

      const [year, month, day] = date.split(/[-/]/).reverse();
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    setYearPicker() {
      if (this.birthday) {
        setTimeout(() => {
          this.max = new Date().toISOString().substr(0, 10);
          this.$refs.picker.activePicker = "YEAR";
        }, 0);
      } else {
        this.max = null;
      }
    },

    focus() {
      this.$refs.campo.focus();
    },

    clear() {
      this.date = "";
      this.dateFormatted = "";
      this.$emit("input", "");
    }
  },

  watch: {
    dateFormatted(newVal) {
      if (newVal === null) {
        this.dateFormatted = '';
      }
    },
  },
};
</script>

<style scoped>
.transparente >>> .v-input__slot {
  background: none;
}

/* .borda >>> .v-input__slot {
    border: 1px solid silver;
    border-radius: 10px
  } */
</style>
