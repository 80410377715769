<template>
  <z-dialogo ref="dialogo" titulo="Conta a Receber" largura="900px" persistent dividers>
    <template v-slot:conteudo>

      <v-form ref="form" v-model="form_valid" lazy-validation>
        <v-row dense>
          <v-col cols="12" sm="2">
            <z-text-field label="Guia" v-model="contareceber.guia.id" readonly></z-text-field>
          </v-col>

          <v-col cols="12" sm="10">
            <z-text-field label="Empresa" v-model="contareceber.guia.empresa.razao" readonly></z-text-field>
          </v-col>

          <v-col cols="12" sm="8">
            <z-text-field label="Código Depósio Judicial" v-model="contareceber.codigo_deposito_judicial" readonly></z-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <z-date-picker-menu label="Vencimento Boleto" v-model="contareceber.vencimento_boleto" readonly></z-date-picker-menu>
          </v-col>

          <v-col cols="12" sm="4">
            <z-campo-valor label="Valor Bruto" v-model="contareceber.valor_bruto" decimais="2" prefix="R$" readonly></z-campo-valor>
          </v-col>

          <v-col cols="12" sm="4">
            <z-campo-valor label="Taxas Bancárias" v-model="contareceber.taxas_bancarias" decimais="2" prefix="R$" @input="(valor) => contareceber.valor_a_pagar = contareceber.valor_bruto - valor"></z-campo-valor>
          </v-col>

          <v-col cols="12" sm="4">
            <z-campo-valor label="Valor a Pagar" v-model="contareceber.valor_a_pagar" decimais="2" prefix="R$" readonly></z-campo-valor>
          </v-col>

          <v-col cols="12" sm="4">
            <z-date-picker-menu label="Recebimento Boleto" v-model="contareceber.data_recebimento_boleto" clearable :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
          </v-col>

        </v-row>
      </v-form>
    </template>

    <template v-slot:actions_right>
      <template v-if="contareceber.recebido">
        <z-delete
          titulo="Cancelar recebimento"
          botaoSim="Cancelar recebimento"
          botaoNao="Não Cancelar"
          cor-botao-sim="red"
          @click:sim="confirmarCancelamento(contareceber)"
          :loading="cancelando"
        >
          Esta recebimento será cancelado. Deseja cancelar?
        </z-delete>

        <v-divider vertical inset class="mx-3 mb-2"></v-divider>
      </template>

      <v-btn depressed @click="fechar" :disabled="salvando">{{ textoBtnCancelar }}</v-btn>
      <v-btn color="primary" :loading="salvando" :disabled="!form_valid" @click="darBaixaContaReceber" v-if="!contareceber.recebido">Receber</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
  import { mapActions, mapStores, mapWritableState } from 'pinia';
  import { useContasReceberStore, useGlobalStore } from '@/app/store/stores';
  import * as regras from '@/app/helpers/validacoes';

  export default {
    name: 'DialogoDarBaixa',

    data() {
      return {
        salvando: false,
        cancelando: false,
        form_valid: true,
        regras: {...regras}
      }
    },

    computed: {
      ...mapWritableState(useContasReceberStore, ['contareceber']),
      ...mapWritableState(useGlobalStore, ['loading']),

      textoBtnCancelar() {
        return this.contareceber.recebido ? 'Fechar' : 'Cancelar';
      }
    },

    methods: {
      ...mapActions(useContasReceberStore, ['abrir', 'darBaixa', 'salvar', 'cancelarRecebimento']),

      abrir() {
        this.$refs.dialogo.abrir();
        this.$emit('abrir');
      },

      fechar() {
        this.$refs.form.resetValidation();
        this.$refs.dialogo.fechar();
        this.$emit('fechar');
      },

      async darBaixaContaReceber() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        try {
          this.salvando = true;
          const dados = {
            id: this.contareceber.id,
            data_recebimento_boleto: this.contareceber.data_recebimento_boleto,
            taxas_bancarias: this.contareceber.taxas_bancarias,
            valor_a_pagar: this.contareceber.valor_a_pagar
          }
          await this.darBaixa(dados);
          this.$eventbus.notificar("Recebimento efetuado sucesso.");
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro("Não foi possível dar baixa. Verifique os dados preenchidos.", error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      async confirmarCancelamento(contareceber) {
        try {
          this.cancelando = true;
          const dados = {
            id: this.contareceber.id,
            valor_a_pagar: this.contareceber.valor_bruto
          }
          await this.cancelarRecebimento(dados);
          this.$eventbus.notificar('Recebimento cancelado sucesso.');
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível cancelar este recebimento. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.cancelando = false;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>