<template>
  <v-navigation-drawer width="400px" v-model="drawer" stateless overlay-opacity="0.2" fixed temporary clipped app>
    <template v-slot:prepend>

      <v-toolbar flat>
        <span class="text--primary subtitle-1 font-weight-bold">Filtro Contas a Pargar - Parceiros</span>
        <v-spacer></v-spacer>
          <v-btn text icon @click="fechar">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </template>

    <v-card flat>
      <v-card-text>
        <!-- <h1 class="text--primary subtitle-1 font-weight-bold">Filtro Contas a Receber</h1> -->

        <v-row dense no-gutters>
          <v-col cols="12">
            <v-autocomplete 
              v-model="filtro.parceiro" 
              label="Parceiro" 
              ref="parceiro" 
              return-object
              filled 
              placeholder=" " 
              clearable
              item-value="id"
              item-text="descricao"
              :items="listaParceiros" 
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete 
              v-model="filtro.empresa" 
              label="Empresa" 
              ref="responsavel" 
              return-object
              filled 
              placeholder=" " 
              clearable
              item-value="id"
              item-text="descricao"
              :items="listaEmpresas" 
            ></v-autocomplete>
          </v-col>

          <!-- <v-col cols="12">
            <v-autocomplete v-model="filtro.empresa" filled ref="responsavel" placeholder=" " label="Cliente Responsável" :items="listaEmpresas" clearable></v-autocomplete>
          </v-col> -->

          <v-col cols="12">
            <z-date-range-picker v-model="filtro.data_pagamento" filled ref="data_pagamento" placeholder=" " label="Intervalo Pagamento" clearable></z-date-range-picker>
          </v-col>

          <v-col cols="12">
            <z-date-range-picker v-model="filtro.data_transferencia" filled ref="data_transferencia" placeholder=" " label="Intervalo Transferência" clearable></z-date-range-picker>
          </v-col>
          <!-- <v-col cols="12">
            <v-select v-model="filtro.status" filled :items="$globals.STATUS_CHEQUE" placeholder=" " label="Situação do Cheque" clearable></v-select>
          </v-col> -->

          <v-col cols="12">
            <v-text-field 
              v-model="filtro.competencia" 
              label="Competência" 
              ref="competencia" 
              filled 
              clearable
            ></v-text-field>
          </v-col>

          <z-select
            v-model="filtro.status"
            label="Status"
            clearable
            return-object
            :items="status"
            @change="filtrar"
          ></z-select>

          <v-col cols="12" align="end">
            <v-btn depressed @click="redefinir" class="text-capitalize">
              <v-icon left>mdi-close</v-icon>Limpar
            </v-btn>
          </v-col>

        </v-row>
        
      </v-card-text>
    </v-card>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn text @click="fechar" class="text-capitalize">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="aplicar" class="text-capitalize" :loading="aplicando">Filtrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-navigation-drawer>
</template>

<script>

  import { mapStores, mapState, mapActions } from "pinia";
  import { useListasStore } from "@/app/store/stores/listas";
  import { datasParaIntervaloBR } from "@/app/helpers/formatacoes";

  export default {
    data() {
      return {
        drawer: false,
        filtro: {
          parceiro: null,
          empresa: null,
          data_pagamento: [],
          data_transferencia: [],
          competencia: null,
          status: null
        },
        aplicando: false,
        filtroChips: [],
        status: [
          { text: 'Em aberto', value: 'aberto'}, 
          { text: 'Transferido', value: 'compensar'}, 
        ]
      }
    },

    computed: {
      ...mapState(useListasStore, ['listaEmpresas', 'listaParceiros'])
    },

    methods: {
      ...mapActions(useListasStore, ['getEmpresas', 'getParceiros']),

      abrir() {
        // this.filtro = this.clone(this.$store.this.cheques.filtro);
        this.drawer = true;
        this.getEmpresas();
        this.getParceiros();
      },

      fechar() {
        this.drawer = false;
      },

      async aplicar() {
        try {
          this.aplicando = true;
          // await this.$store.dispatch('cheques/filtrarCheques', this.filtro);
          this.processarFiltro();
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro(error);
          throw error;
          // this.$eventbus.erroSistema('Não foi possível aplicar o filtro.');
        } finally {
          this.aplicando = false;
        }
      },

      redefinir() {
        this.filtro = {
          parceiro: null,
          empresa: null,
          data_pagamento: [],
          data_transferencia: [],
          competencia: null,
          status: null
        }

        this.aplicar(true);
      },

      validar() {
        if ( this.filtro.empresa == null && this.filtro.status == null && this.filtro.banco == null && this.filtro.data_vencimento.length == 0 && this.filtro.data_recebimento.length == 0)
            return false;

          return true;
      },

      processarFiltro() {
        let items = [];
        
        if (this.filtro.parceiro) {
          items.push({descricao: 'Parceiro: ' + this.filtro.parceiro.descricao, chave: 'parceiro', valor: this.filtro.parceiro.id});
        }

        if (this.filtro.empresa) {
          items.push({descricao: 'Empresa: ' + this.filtro.empresa.descricao, chave: 'empresa', valor: this.filtro.empresa.id});
        }

        if (this.filtro.data_pagamento.length > 0) {
          let intervalo = datasParaIntervaloBR(this.filtro.data_pagamento);
          items.push({descricao: 'Vencimento: de ' + intervalo, chave: 'data_pagamento', valor: this.filtro.data_pagamento});
        }

        if (this.filtro.data_transferencia.length > 0) {
          let intervalo = datasParaIntervaloBR(this.filtro.data_transferencia);
          items.push({descricao: 'Recebimento: de ' + intervalo, chave: 'data_transferencia', valor: this.filtro.data_transferencia});
        }

        if (this.filtro.competencia) {
          items.push({descricao: 'Competência: ' + this.filtro.competencia, chave: 'competencia', valor: this.filtro.competencia});
        }

        if (this.filtro.status) {
          items.push({descricao: 'Status: ' + this.filtro.status.text, chave: 'status', valor: this.filtro.status.value});
        }

        this.filtroChips = items;

        this.$emit('input', this.filtroChips);
      },

      removerFiltro(chip) {
        let isArray = Array.isArray(this.filtro[chip.chave]);

        if (isArray) {
          this.filtro[chip.chave] = [];
        } else {
          this.filtro[chip.chave] = '';
        }

        let index = this.filtroChips.findIndex(chip_ => chip_.chave == chip.chave);
        this.filtroChips.splice(index, 1);

        this.$emit('input', this.filtroChips);
      },

    },

  }
</script>

<style scoped>

</style>