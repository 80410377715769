import { defineStore } from 'pinia'

import config from "@/app/config/app";
import createAxios from "@/app/core/AxiosCore";
const axios = createAxios(config);


export const useGlobalStore = defineStore('global', {
  state: () => ({
    titulo_tela: config.APP_TITLE,
    loading: false,
    drawerRight: false,
    configuracoes: {},
    lista_configuracoes: [],
    drawer: true,
  }),

  getters: {},

  actions: {

    setTitulo(titulo) {
      this.titulo_tela = titulo;
    },

    LOADING(loading) {
      this.loading = loading;
    },

    LOADING_ON() {
      this.loading = true;
    },

    LOADING_OFF() {
      this.loading = false;
    },

    TOGGLE_RIGHT_DRAWER() {
      this.drawerRight = !this.drawerRight;
    },

    SET_CONFIGURACOES(configuracoes) {
      this.configuracoes = configuracoes;
    },

    SET_LISTA_CONFIGURACOES(configuracoes) {
      this.lista_configuracoes = configuracoes;
    },

    carregarConfiguracoes({ commit }) {
      axios.get("configuracoes").then(response => {
        this.SET_CONFIGURACOES(response.data);
      });
    },

    carregarListaConfiguracoes({ commit }) {
      return axios.get("configuracoes/lista").then(response => {
        this.SET_LISTA_CONFIGURACOES(response.data);
      });
    },

    atualizar(configuracao) {
      return axios.put("configuracoes", configuracao);
    },

    salvar(configuracao) {
      return axios.post("configuracoes", configuracao);
    },
  },
})