<template>
  <z-dialogo ref="dialogo" titulo="Parceiro" largura="900px" persistent dividers eager>
    <template v-slot:conteudo>

      <v-form ref="form" v-model="form_valid" lazy-validation>
         <v-row dense>
            <v-col cols="12" sm="12">
              <z-select
                v-model="parceiro.parceiro_id"
                label="Parceiro"
                return-object_
                item-value="id"
                item-text="descricao"
                :items="parceiros"
                :rules="[regras.campoObrigatorio]"
                :readonly="!adicionando"
              ></z-select>
            </v-col>

            <v-col cols="12" sm="4">
              <z-select
                v-model="parceiro.tipo_guia"
                label="Tipo de Guia"
                :items="tiposDeGuias"
                :rules="[regras.campoObrigatorio]"
              ></z-select>
            </v-col>

            <v-col cols="12" sm="4">
              <z-date-picker-menu label="Data limite" v-model="parceiro.data_limite" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <z-campo-valor label="Comissão" v-model="parceiro.percentual_comissao" decimais="2" suffix="%" :rules="[regras.maiorQueZero]"></z-campo-valor>
            </v-col>
          </v-row>
      </v-form>
    </template>

    <template v-slot:actions_right>
      <v-btn text @click="cancelar" :disabled="salvando">Cancelar</v-btn>
      <v-btn text color="primary" :loading="salvando" :disabled="!form_valid" @click="salvar">Salvar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
  import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
  import * as regras from '@/app/helpers/validacoes';
  import { jaExiste } from '@/app/helpers/utils';

  export default {
    name: 'DialogoComissaoParceiro',

    props: {
      adicionando: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        salvando: false,
        form_valid: true,
        regras: {...regras}
      }
    },

    computed: {
      ...mapGetters("listas", ["empresas", "parceiros"]),
      ...mapState("empresas", ["empresa", "parceiro"]),

      tiposDeGuias() {

        return this.$globals.TIPOS_GUIAS.filter(tipo => {
          if (this.empresa.regime_tributario == 'simples') {
            return ['SIMPLES', 'DARF_INSS'].indexOf(tipo.value) > -1;
          } else {
            return ['DARF_INSS', 'DARF_PIS', 'DARF_COFINS', 'DARF_CSLL', 'DARF_IRPJ'].indexOf(tipo.value) > -1;
          }
        })
      },
    },

    methods: {
      ...mapMutations('empresas', ['ZERAR_PARCEIRO']),
      ...mapActions('empresas', ['salvarParceiro']),

      abrir() {
        this.old_parceiro = {...this.parceiro };
        this.$store.dispatch('listas/parceiros');
        this.$refs.dialogo.abrir();
        this.$emit('abrir');
      },
      
      fechar() {
        setTimeout(() => {
          this.$refs.form.resetValidation();
          this.ZERAR_PARCEIRO();
        }, 0);
        this.$refs.dialogo.fechar();
        this.$emit('fechar');
      },

      async salvar() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        // if (this.adicionando && jaExiste(this.empresa.parceiros, this.parceiro.parceiro_id)) {
        //   return false;
        // }
          
        try {
          this.salvando = true;
          this.parceiro.empresa_id = this.empresa.id;
          this.parceiro.adicionando = this.adicionando;

          await this.salvarParceiro(this.parceiro);

          this.$eventbus.notificar("Parceiro salvo com sucesso.");
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro("Não foi possível salvar parceiro. Verifique os dados preenchidos.", error);
          throw error;
        } finally {
          this.salvando = false;
        }

      },

      cancelar() {
        this.fechar();
        this.$emit('cancelar')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>