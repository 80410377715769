<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('navIconClick')"></v-app-bar-nav-icon>

    <v-btn icon @click="goHome" v-if="$vuetify.breakpoint.mdAndUp">
      <v-icon>{{ $icon.APP_ICON }}</v-icon>
    </v-btn>

    <v-toolbar-title>
      <span class="mr-2 apontador" @click="goHome">{{ $config.APP_NAME }}</span>
      <span class="text-caption mr-2">{{ $config.APP_VERSION }}</span>
    </v-toolbar-title>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-divider vertical inset class="ml-4"></v-divider>

      <menu-principal></menu-principal>
    </template>

    <v-spacer></v-spacer>
    <v-badge :value="counterStore.count > 0" v-if="!$config.IS_PRODUCTION" color="pink" :content="counterStore.count">Counter</v-badge>
    <v-toolbar-items>
      <app-user-menu></app-user-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script setup>
  import { useCounterStore } from '@/app/store/stores/counter'
  import { useRouter, useRoute } from '@/app/router/vue-router'

  import AppUserMenu from "./AppUserMenu";
  import MenuPrincipal from "./menus/MenuPrincipal";
  import MenuPrincipalDropdown from "./menus/MenuPrincipalDropdown";

  const router = useRouter();
  const counterStore = useCounterStore();

  function goHome() {
    router.push({ name: "Home" }).catch((error) => {});
  }
</script>

<style scoped></style>
