<template>
  <z-dialogo ref="dialogo" titulo="Conta a Pagar" largura="900px" persistent dividers>
    <template v-slot:conteudo>

      <v-form ref="form" v-model="form_valid" lazy-validation>
        <v-row dense>
          <v-col cols="12" sm="12">
            <z-text-field label="Empresa" v-model="contapagar.guia.empresa.razao" readonly></z-text-field>
          </v-col>
          
          <v-col cols="12" sm="12">
            <z-text-field label="Parceiro" v-model="contapagar.parceiro.nome" readonly></z-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <z-date-picker-menu label="Data Pagamento" v-model="contapagar.data_pagamento" readonly></z-date-picker-menu>
          </v-col>

          <v-col cols="12" sm="4">
            <z-campo-valor label="Comissão" v-model="contapagar.valor_transferencia" decimais="2" prefix="R$" readonly></z-campo-valor>
          </v-col>

          <v-col cols="12" sm="4">
            <z-date-picker-menu label="Data Transferência" v-model="contapagar.data_transferencia" clearable :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
          </v-col>

        </v-row>
      </v-form>
    </template>

    <template v-slot:actions_right>
      <template v-if="contapagar.pago">
        <z-delete
          titulo="Cancelar pagamento"
          botao-sim="Sim"
          botao-nao="Não"
          cor-botao-sim="red"
          :loading="cancelando"
          @click:sim="confirmarCancelamento(contapagar)"
        >
          Este pagamento será cancelado. Deseja cancelar?
        </z-delete>

        <v-divider vertical inset class="mx-3 mb-2"></v-divider>
      </template>

      <v-btn text @click="fechar" :disabled="salvando">{{ textoBtnCancelar }}</v-btn>
      <v-btn depressed color="primary" :loading="salvando" :disabled="!form_valid" @click="pagarConta" v-if="!contapagar.pago">Pagar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
  import { mapActions, mapStores, mapWritableState } from 'pinia';
  import { useContasPagarStore, useGlobalStore } from '@/app/store/stores';
  import * as regras from '@/app/helpers/validacoes';

  export default {
    name: 'DialogoDarBaixaContasPagar',

    data() {
      return {
        salvando: false,
        cancelando: false,
        form_valid: true,
        regras: {...regras}
      }
    },

    computed: {
      ...mapWritableState(useContasPagarStore, ['contapagar']),
      ...mapWritableState(useGlobalStore, ['loading']),

      textoBtnCancelar() {
        return this.contapagar.pago ? 'Fechar' : 'Cancelar';
      }
    },

    methods: {
      ...mapActions(useContasPagarStore, ['abrir', 'pagar', 'salvar', 'cancelarPagamento']),

      abrir() {
        this.$refs.dialogo.abrir();
        this.$emit('abrir');
      },

      fechar() {
        this.$refs.form.resetValidation();
        this.$refs.dialogo.fechar();
        this.$emit('fechar');
      },

      async pagarConta() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        try {
          this.salvando = true;
          await this.pagar(this.contapagar, this.contapagar.data_transferencia);
          this.$eventbus.notificar("Pagamento efetuado com sucesso.");
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro("Não foi possível pagar conta. Verifique os dados preenchidos.", error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      async confirmarCancelamento(contapagar) {
        try {
          this.cancelando = true;
          await this.cancelarPagamento(contapagar);
          this.$eventbus.notificar('Pagamento cancelado sucesso.');
          this.fechar();
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível cancelar este pagamento. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.cancelando = false;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>