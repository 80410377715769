<template>
  <div>
    <v-container>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" align="center">
          <v-avatar color="primary" size="100">
            <v-icon size="70" dark>{{ $icon.APP_ICON }}</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols="12" mb-4 align="center">
          <h1 class="display-2 mb-3">Bem-vindo ao <span class="font-weight-bold">Sistema</span></h1>
          <p class="overline" style="font-size: 12px !important">
            Controle de Créditos Contábeis
          </p>
        </v-col>
      </v-row>

    </v-container>

    <v-container v-if="!$config.IS_PRODUCTION">
      <v-row>
        <v-col>
          <v-btn color="primary" @click="increment">{{ count }} Double: {{ double }}</v-btn>
          <v-btn color="primary" @click="teste" :loading="loading">{{ blah }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-chip>{{ person }}</v-chip> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>{{ testeSetup }}</h3>
          <h3>Hello World!!</h3>
          {{ count }}
          <v-btn color="pink" dark @click="increment">Press Me</v-btn>
        </v-col>
      </v-row>

      <about></about>

      <v-row>
        <v-col>
          <h3>Abrir Dialogo Setup</h3>
          <v-btn color="orange" dark @click="abrirDialogo">Abrir dialogo</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Abrir Dialogo Setup</h3>
          <v-btn color="orange" dark @click="eventsStore.alertar">{{ alerta }}</v-btn>
        </v-col>
        <v-col>
          <v-btn color="teal" dark @click="$router.push({name: 'PadraoLista'})">Teste Padrão</v-btn>
          <z-delete
            titulo="Remover parceiro1"
            botaoSim="Remover"
            botaoNao="Cancelar"
            corBotaoSim="red"
            :loading="excluindo"
          >
            A parceiro será removido do sistema. Deseja remover1?
          </z-delete>
          <z-delete
            titulo="Remover parceiro2"
            botaoSim="Remover"
            botaoNao="Cancelar"
            corBotaoSim="red"
            :loading="excluindo"
          >
            A parceiro será removido do sistema. Deseja remover2?
          </z-delete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Composables</h3>
          <p>Mouse position is at: {{ x }}, {{ y }}</p>
        </v-col>
      </v-row>

      <z-dialogo-sim-nao
        ref="dialogoRemover"
        titulo="Remover parceiro"
        botaoSim="Remover"
        botaoNao="Cancelar"
        :loading="excluindo"
      >
        A parceiro será removida do sistema. Deseja remover?
      </z-dialogo-sim-nao>
    </v-container>
  </div>
</template>

<script setup>
    import { ref, reactive, watch, computed, onMounted, onUnmounted } from "vue";
    import { useCounterStore } from '@/app/store/stores/counter'
    import { useEventsStore } from '@/app/store/stores/events'
    // import { mapStores, mapActions, mapState } from 'pinia';
    import { storeToRefs } from 'pinia'

    import About from "./About";
    import { useMouse } from '@/app/composables/mouse'
    import { useAlerta } from '@/app/composables/alertas'

    
    const counterStore = useCounterStore();

    let loading = ref(false);

    const { count, counter, double, person, blah } = storeToRefs(counterStore);
    const { increment } = counterStore;

    // const { getBlah, setBlah } = counterStore;
    async function teste() {
        counterStore.setBlah({});
        loading.value = true;
        await counterStore.getBlah();
        loading.value = false;
    }

    let testeSetup = ref('testeSetup Bem-Vindo');
    let excluindo = ref(false);

    const dialogoRemover = ref();

    async function abrirDialogo() {
      let response = await dialogoRemover.value.abrir();
      if (response) {
        excluindo.value = true;
        console.log('Removido');
      } else {
        console.log('Não removido');
      }
    }

    const eventsStore = useEventsStore();
    // const alerta = computed(() => eventsStore.alerta);

    const {alerta} = useAlerta(eventsStore);



    const { x, y } = useMouse();
    
</script>

<style>
.sublinhado {
  text-decoration: underline;
}
</style>
