import zDialogo from '@/components/zDialogo'
import zDialogoSimNao from '@/components/zDialogoSimNao'

import zCampoValor from '@/components/zforms/zCampoValor'
import zDatePicker from '@/components/zforms/zDatePicker'
import zDateRangePicker from '@/components/zforms/zDateRangePicker'
import zDatePickerMenu from '@/components/zforms/zDatePickerMenu'
import zTimePicker from '@/components/zforms/zTimePicker'
import zCpfCnpj from '@/components/zforms/zCpfCnpj'
import zCep from '@/components/zforms/zCep'
import zDateNavigation from '@/components/zforms/zDateNavigation'
import zCampoTexto from "@/components/zforms/zCampoTexto"
import zTextField from "@/components/zforms/zTextField"
import zBusca from '@/components/zforms/zBusca'
import zTextFieldSearch from '@/components/zforms/zTextFieldSearch'
import zSelect from '@/components/zforms/zSelect'
import zTextarea from '@/components/zforms/zTextarea'
import zAutocomplete from '@/components/zforms/zAutocomplete'
import zMenuButton from "@/components/zMenuButton";
import zDialogoImpressao from "@/components/zDialogoImpressao";

import zContainer from "@/components/zContainer"
import zCentralizedToolbar from "@/components/zCentralizedToolbar"
import zScrollLayout from "@/components/zScrollLayout"
import zMensagem from '@/components/zMensagem'
import zLoading from '@/components/zLoading'
import zAguardar from '@/components/zAguardar'
import zToolbarDynamicMenu from "@/components/zToolbarDynamicMenu"
import zTituloTela from "@/components/base/zTituloTela"
import zCabecalho from "@/components/base/zCabecalho"
import zMenuImprimir from "@/components/zMenuImprimir"
import zCabecalhoWithActions from "@/components/base/zCabecalhoWithActions"

import zNotificacao from "@/components/zNotificacao"
import zAlerta from "@/components/zAlerta"

import zDelete from "@/components/zDelete"


export default function ZComponents() {

  function install(Vue, options) {

    Vue.component('zDialogo', zDialogo);
    Vue.component('zDialogoSimNao', zDialogoSimNao);

    Vue.component('zCampoValor', zCampoValor);
    Vue.component('zDatePicker', zDatePicker);
    Vue.component('zDateRangePicker', zDateRangePicker);
    Vue.component('zDatePickerMenu', zDatePickerMenu);
    Vue.component('zTimePicker', zTimePicker);
    Vue.component('zCpfCnpj', zCpfCnpj);
    Vue.component('zCep', zCep);
    Vue.component('zDateNavigation', zDateNavigation);
    Vue.component('zCampoTexto', zCampoTexto);
    Vue.component('zTextField', zTextField);
    Vue.component('zBusca', zBusca);
    Vue.component('zTextFieldSearch', zTextFieldSearch);
    Vue.component('zSelect', zSelect);
    Vue.component('zTextarea', zTextarea);
    Vue.component('zAutocomplete', zAutocomplete);
    Vue.component('zMenuButton', zMenuButton);
    Vue.component("zDialogoImpressao", zDialogoImpressao);

    Vue.component('zContainer', zContainer);
    Vue.component('zCentralizedToolbar', zCentralizedToolbar);
    Vue.component('zScrollLayout', zScrollLayout);
    Vue.component('zMensagem', zMensagem);
    Vue.component('zLoading', zLoading);
    Vue.component('zAguardar', zAguardar);
    Vue.component('zToolbarDynamicMenu', zToolbarDynamicMenu);
    Vue.component('zTituloTela', zTituloTela);
    Vue.component('zCabecalho', zCabecalho);
    Vue.component('zMenuImprimir', zMenuImprimir);
    Vue.component('zCabecalhoWithActions', zCabecalhoWithActions);
    
    Vue.component('zNotificacao', zNotificacao);
    Vue.component('zAlerta', zAlerta);

    Vue.component('zDelete', zDelete);
  }
  
  return {
    install
  }
}
