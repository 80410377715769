<template>
  <div>
    <z-centralized-toolbar fluid>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text color="primary" @click="adicionar">
          <v-icon left>{{ $icon.ADICIONAR }}</v-icon>Adicionar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          @click="refresh"
          :loading="$store.state.loading"
        >
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="filtro.busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="filtro.paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="empresas"
            item-key="id"
          >
            <template v-slot:item="{ item: empresa }">
              <tr>
                <td>{{ empresa.id }}</td>
                <td>
                  <a class="text-decoration-none" @click.prevent="editar(empresa)">
                    {{ empresa.razao }}
                  </a>
                </td>
                <td class="text-center">{{ empresa.parceiros_count }}</td>
                <td>{{ empresa.regime_tributario | capitalized }}</td>
                <td class="text-right">{{ empresa.telefone_celular }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(empresa)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <!-- <v-btn icon @click="perguntarExcluir(empresa)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                  <z-delete
                    titulo="Remover empresa"
                    botaoSim="Remover"
                    botaoNao="Cancelar"
                    cor-botao-sim="red"
                    largura="300px"
                    @click:sim="confirmarExcluir(empresa)"
                    :loading="excluindo"
                  >
                    A empresa {{ empresa.razao }} será removida do sistema. Deseja remover?
                  </z-delete>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemover"
      titulo="Remover empresa"
      botaoSim="Remover"
      botaoNao="Cancelar"
      cor-botao-sim="red"
      :loading="excluindo"
    >
      A empresa será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "EmpresasLista",

  data() {
    return {
      titulo_tela: 'Empresas',
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Razão Social", value: "razao" },
        { text: "Parceiros", value: "parceiros_count", width: "100px", align: "center" },
        { text: "Regime Tributário", value: "regime_tributario" },
        { text: "Celular", value: "telefone_celular", align: "right" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      empresa_codigo: "",
    };
  },

  computed: {
    ...mapState(["loading"]),
    ...mapState("empresas", ["empresas", "empresa", "total_itens", "filtro"]),
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo(this.titulo_tela);
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("empresas", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível listar as empresas.", error);
      }
    },

    buscar() {
      this.$store.commit("empresas/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.filtro.busca = null;
      this.$store.commit("empresas/SET_PAGE", 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit("empresas/ZERAR");
      this.$router.push({ name: "EmpresasAdicionar" });
    },

    async editar(empresa) {
      try {
        await this.abrir(empresa.id);
        this.$router.push({ name: "EmpresasEditar", params: { id: empresa.id } });
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível abrir a empresa.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(empresa) {
      this.$refs.dialogoRemover.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(empresa);
          this.$refs.dialogoRemover.fechar();
        }
      });
    },

    async confirmarExcluir(empresa) {
      try {
        this.excluindo = true;
        await this.excluir(empresa.id);
        this.$eventbus.notificar("Empresa excluída com sucesso!");
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível excluir o empresa!", error);
        throw error;
      } finally {
        this.excluindo = false;
      }
    }
  },

  watch: {
    'filtro.paginacao': {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
