<template>
  <div>
    <z-centralized-toolbar cols="12" fluid>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text color="primary" @click="adicionar">
          <v-icon left>{{ $icon.ADICIONAR }}</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
        <!-- <v-btn text id="btn-atualizar" @click="guiasStore.listar(filtro)" :loading="globalStore.loading">
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn> -->
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <v-divider vertical inset></v-divider>

      <z-text-field-search
        v-if="false"
        v-model="filtro.busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>

      <z-autocomplete
        v-model="filtro.empresa_id"
        class="ml-2"
        label="Empresa"
        dense
        rounded-lg
        outlined
        hide-details
        config-off
        item-value="id"
        item-text="descricao"
        :style="$vuetify.breakpoint.mdAndDown ? 'max-width: 500px' : 'max-width: 700px'" 
        clearable
        :items="empresas"
        @change="filtrar"
      ></z-autocomplete>
      <z-select
        v-model="filtro.mes"
        class="ml-5"
        label="Mês Comp."
        dense
        rounded-lg
        outlined
        hide-details
        config-off
        clearable
        :items="$globals.MESES"
        style="max-width: 150px"
        @change="filtrar"
      ></z-select>
      <z-select
        v-model="filtro.ano"
        class="ml-5"
        label="Ano"
        dense
        rounded-lg
        outlined
        hide-details
        config-off
        clearable
        :items="anos"
        style="max-width: 130px"
        @change="filtrar"
      ></z-select>
      <z-select
        v-model="filtro.status"
        class="ml-5"
        label="Status"
        dense
        rounded-lg
        outlined
        single-line_
        hide-details
        config-off
        clearable
        :items="statusGuias"
        style="max-width: 200px"
        @change="filtrar"
      ></z-select>
      <!-- <v-toolbar-items v-show="filtro.empresa_id != null">
        <v-btn id="btn-adicionar" text color="primary" @click="limparFiltro"> Limpar Filtro </v-btn>
      </v-toolbar-items> -->
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container cols="10">
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <z-container class="text-center" v-if="guias.length == 0">
          <v-icon color="primary" size="100">{{ $icon.GUIAS }}</v-icon>
          <h2 class="mt-12">Escolha uma empresa e o mês e ano de competência acima</h2>
        </z-container>

        <v-card v-else>
          <v-data-table
            mobile-breakpoint="0"
            item-key="id"
            disable-sort
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="guias"
          >
            <template v-slot:item="{ item: guia }">
              <tr>
                <td>
                  <a class="text-link" @click.prevent="editar(guia)">
                    {{ guia.id }}
                  </a>
                </td>
                <!-- <td class="text-center">{{ guia.data_recebimento_guia | dataBR }}</td> -->
                <td>
                  <a class="text-link" @click.prevent="()=> { filtro.empresa_id = guia.empresa_id; filtrar() }">
                    {{ guia.empresa.razao }}
                  </a>
                </td>
                <td>{{ guia.tipo_guia }}</td>
                <td class="text-right">{{ guia.valor_guia | valorBR }}</td>
                <td class="text-right font-weight-bold">{{ guia.valor_a_pagar | valorBR }}</td>
                <td class="text-center">{{ guia.competencia_mes + "/" + guia.competencia_ano }}</td>
                <td class="text-center">{{ guia.data_compensacao | dataBR }}</td>
                <td class="text-center">{{ guia.data_recebimento_boleto | dataBR }}</td>
                <td class="text-center">
                  <v-chip v-if="!guia.data_compensacao" color="blue" dark small>A Compensar</v-chip>
                  <v-chip v-else-if="guia.data_recebimento_boleto" color="green" dark small>Pago</v-chip>
                  <v-chip v-else color="red" dark small>A Receber</v-chip>
                </td>
                <td class="text-center">
                  <!-- <v-btn icon @click="editar(guia)">
                    <v-icon>{{ $icon.EDITAR }}</v-icon>
                  </v-btn> -->

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-badge
                        bordered
                        color="error"
                        overlap
                        :value="guia.arquivos_count"
                        offset-x="13"
                        offset-y="10"
                        dot
                      >
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon 
                          @click="abrirArquivos(guia)"
                        >
                          <v-icon>{{ $icon.ANEXOS }}</v-icon>
                        </v-btn>
                      </v-badge>
                    </template>
                    <span>Arquivos</span>
                  </v-tooltip>

                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        v-bind="attrs"
                        v-on="on"
                        icon 
                        @click="compensar(guia)"
                      >
                        <v-icon>{{ $icon.MONEY }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Compensar</span>
                  </v-tooltip> -->

                  <!-- <z-delete
                    titulo="Remover guia"
                    botaoSim="Remover"
                    botaoNao="Cancelar"
                    cor-botao-sim="red"
                    @click:sim="confirmarExcluir(guia)"
                    :loading="excluindo"
                  >
                    A guia será removida do sistema. Deseja remover?
                  </z-delete> -->

                  <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="editar(guia)">
                        <v-list-item-action>
                          <v-icon>{{ $icon.EDITAR }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title v-if="guia.data_recebimento_boleto">Exibir guia</v-list-item-title>
                        <v-list-item-title v-else>Editar</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="compensar(guia)">
                        <v-list-item-action>
                          <v-icon>{{ $icon.MONEY }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title v-if="guia.data_recebimento_boleto || guia.data_compensacao">
                          Exibir Compensação
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          Compensar
                        </v-list-item-title>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item @click="perguntarExcluir(guia)" :disabled="!!guia.data_recebimento_boleto">
                        <v-list-item-action>
                          <v-icon>{{ $icon.EXCLUIR }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Remover guia</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <dialogo-compensacao ref="dialogoCompensar"></dialogo-compensacao>
    <arquivos ref="arquivos" :guia="guia"></arquivos>
    <z-dialogo-sim-nao 
      ref="dialogoExcluir" 
      titulo="Remover Guia?" 
      botaoSim="Remover" 
      botaoNao="Cancelar"
      cor-botao-sim="red"
      :loading="excluindo"
    >
      A guia será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

    <z-dialogo-sim-nao ref="dialogoExcluir_" titulo="Remover usuário?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O usuário será removido do sistema.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import { range } from "@/app/helpers/utils";
import { mapStores, mapWritableState } from 'pinia';
import { useGuiasStore, useGlobalStore } from '@/app/store/stores';

import DialogoCompensacao from "./DialogoCompensacao.vue";
import Arquivos from './Arquivos'

export default {
  name: "GuiasLista",

  components: {
    DialogoCompensacao,
    Arquivos
  },

  data() {
    return {
      titulo_tela: "Controle de Guias Lançadas",
      headers: [
        { text: "Número", value: "id", width: "90px" },
        { text: "Empresa", value: "empresa_id" },
        { text: "Tipo Guia", value: "tipo_guia" },
        { text: "Valor Guia", value: "valor_guia", align: "right" },
        { text: "Valor a Pagar", value: "valor_a_pagar", align: "right" },
        { text: "Competência", align: "center" },
        { text: "Data Comp.", value: "data_compensacao", align: "center" },
        { text: "Data Pagamento", value: "data_recebimento_boleto", align: "center" },
        { text: "Status", value: "status", align: "center" },
        {
          text: "Opções",
          value: "",
          width: "150px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      guia_codigo: "",
      statusGuias: [
        { text: 'A Receber', value: 'receber'}, 
        { text: 'A Compensar', value: 'compensar'}, 
        { text: 'Pago', value: 'pago'}, 
      ]
    };
  },

  computed: {
    ...mapStores(useGuiasStore, useGlobalStore),
    ...mapState(["loading"]),
    ...mapState("guias", ["guias", "guia", "total_itens", 'filtro', 'paginacao']),
    ...mapGetters("listas", ["empresas", "parceiros"]),

    anos() {
      return range(new Date().getFullYear(), 2020);
    },

    paginacao: {
      get () {
        return this.$store.state.guias.paginacao
      },
      set (paginacao) {
        this.$store.commit('guias/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    const isLeavingToForm = ['GuiasEditar', 'GuiasAdicionar'].indexOf(to.name) > -1;
    if (isLeavingToForm) {
      this.filtro.busca = null;
      next();
      return;
    }
    this.limparFiltro();
    next();
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo(this.titulo_tela);
    this.$store.dispatch("listas/empresas");
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("guias", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível listar as guias.", error);
      }
    },

    buscar() {
      this.$store.commit("guias/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.filtro.busca = null;
      this.$store.commit("guias/SET_PAGE", 1);
      this.refresh();
    },

    adicionar() {
      if (!this.filtro.empresa_id) {
        this.$eventbus.alertar("Escolha uma empresa no filtro para poder adicionar uma guia");
        return false;
      }
      this.$store.commit("guias/ZERAR");
      this.$router.push({ name: "GuiasAdicionar" });
    },

    async editar(guia) {
      try {
        await this.abrir(guia.id);
        this.$router.push({ name: "GuiasEditar", params: { id: guia.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a guia.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    async compensar(guia) {
      try {
        await this.abrir(guia.id);
        this.$refs.dialogoCompensar.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a guia.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    async perguntarExcluir(guia) {
      this.$refs.dialogoExcluir.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(guia);
          this.$refs.dialogoExcluir.fechar();
        }
      });
    },

    async confirmarExcluir(guia) {
      try {
        this.excluindo = true;
        await this.excluir(guia.id);
        this.$eventbus.notificar("Guia excluída com sucesso!");
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível excluir o guia!", error);
        throw error;
      } finally {
        this.excluindo = false;
      }
    },


    filtrar() {
      this.filtro.busca = null;
      this.paginacao.page = 1;
      this.refresh();
    },

    async abrirArquivos(guia) {
      await this.abrir(guia.id);
      this.$refs.arquivos.abrir();
    },

    limparFiltro() {
      this.filtro.busca = null;
      this.filtro.empresa_id = null;
      this.filtro.ano = new Date().getFullYear();
      this.filtro.mes = null; //new Date().getMonth() + 1;

      this.refresh();
    }
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
