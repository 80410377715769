import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

export default function createCrudApi(url) {
  
  function listar(paginacao) {
    return axios.get(url, { params: paginacao }).then((response) => response.data);
  }
  
  function filtrarLetra(letra) {
    return axios.get(url, { params: { letra } }).then((response) => response.data);
  }
  
  function buscar(busca) {
    return axios.get(`${url}/buscar?busca=${busca}`).then((response) => response.data);
  }
  
  function buscarCodigo(codigo) {
    return axios.get(`${url}/buscarCodigo?codigo=${codigo}`).then((response) => response.data);
  }
  
  function abrir(id) {
    return axios.get(`${url}/${id}`).then((response) => response.data);
  }
  
  function salvar(objeto) {
    return axios.post(url, objeto).then((response) => response.data);
  }
  
  function atualizar(objeto) {
    return axios.put(`${url}/${objeto.id}`, objeto).then((response) => response.data);
  }
  
  function excluir(id) {
    return axios.delete(`${url}/${id}`).then((response) => response.data);
  }
  
  return {
    url,
    axios,
    listar,
    filtrarLetra,
    buscar,
    buscarCodigo,
    abrir,
    salvar,
    atualizar,
    excluir,
  };

}

