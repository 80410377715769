export default {
  user_id: null,
  data_cadastro: (new Date).toISOString().substring(0, 10),
  razao: '',
  fantasia: '',
  endereco: '',
  bairro: '',
  cidade: '',
  estado: '',
  cep: '',
  complemento: '',
  contato: '',
  cpf_cnpj: '',
  regime_tributario: 'simples',
  telefone_fixo: '',
  telefone_celular: '',
  email: ''
}