<template>
  <div>

    <z-centralized-toolbar>  
      <v-toolbar-items>

        <v-btn id="btn-adicionar" text color="primary" @click="adicionar">
          <v-icon left>{{ $icon.ADICIONAR }}</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="listar" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>

      </v-toolbar-items>
      

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="search"
        select
        class="ml-5"
        width="250px"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>

      
      <v-spacer></v-spacer>

      <v-btn id="btn-adicionar" color="primary" @click="$router.push({name: 'Permissoes'})">
        <v-icon left>{{ $icon.PERMISSOES }}</v-icon>Permissões
      </v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0" 
            :headers="headers" 
            :items="usuarios" 
            item-key="id" 
            :loading="false" 
            :search="search"
          >
            <template v-slot:item="{ item: usuario }">
              <tr>
                <td>{{ usuario.id }}</td>
                <td>{{ usuario.name }}</td>
                <td>{{ usuario.email }}</td>
                <td>
                  <v-chip small color="blue lighten-3">{{ usuario.perfil_usuario.descricao }}</v-chip>
                </td>
                <td class="text-center">
                  <v-chip v-if="usuario.ativo == 1" small color="green lighten-3">Ativo</v-chip>
                  <v-chip v-else small color="red lighten-3">Inativo</v-chip>
                </td>
                <td class="text-center">
                  <v-btn icon @click="editar(usuario)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(usuario)" class="ma-0" v-if="usuario.name != 'Administrador'">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoExcluir" titulo="Remover usuário?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O usuário será removido do sistema.
    </z-dialogo-sim-nao>

    <dialogo-usuarios-form ref="usuarioForm"></dialogo-usuarios-form>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import DialogoUsuariosForm from './DialogoUsuariosForm.vue';

export default {
  name: 'UsuariosLista',

  components: { DialogoUsuariosForm },

  data() {
    return {
      // globals,
      headers: [
        { text: "Código", value: "id", width: "100px" },
        { text: "Nome", value: "name" },
        { text: "Login", value: "email" },
        { text: "Perfil", value: "perfil_usuario.descricao" },
        { text: "Situação", value: "ativo", align: "center" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false }
      ],
      search: "",
      pagination: {},
      excluindo: false,
      usuario: {},
      listagem: 'Recentes'
    };
  },

  created() {
    this.setTitulo('Usuários');
    this.listar();
  },

  mounted() {
    this.$store.dispatch('listas/perfis');
  },

  methods: {
    ...mapMutations(['setTitulo']),
    ...mapActions('usuarios', ['listar', 'excluir', 'abrir']),

    async editar(usuario) {
      await this.abrir(usuario.id);
      // this.$router.push({ name: "UsuariosEditar", params: { id: usuario.id } });
      this.$refs.usuarioForm.abrir();
    },

    perguntarExcluir(usuario) {
      this.$refs.dialogoExcluir.abrir()
        .then(async response => {

          if (response == true) {
            await this.confirmarExcluir(usuario);
            // this.$refs.dialogoExcluir.fechar();
          }
        })
    },

    async confirmarExcluir(usuario) {
      try {
        this.excluindo = true;
        await this.excluir(usuario.id);
      } finally {
        this.$refs.dialogoExcluir.fechar();
        this.excluindo = false;
        this.$eventbus.notificar('Usuário excluído com sucesso!');
      }
    },

    limparBusca() {
      this.busca = null;
      // this.$store.commit('bancos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$refs.usuarioForm.abrir();
      // this.$store.commit('usuarios/ZERAR');
      // this.$router.push({ name: "UsuariosAdicionar" });
    },
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapState("usuarios", ["usuarios"])
  }
};
</script>

<style scoped>
</style>
