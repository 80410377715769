import contasReceberApi from "@/app/services/contasReceberApi";
import contareceber from "@/app/store/models/contareceber";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    contareceber: {...contareceber},
    contasreceber: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, contasreceber) {
      state.contasreceber = contasreceber;
    },

    ABRIR(state, contareceber) {
      state.contareceber = contareceber;
    },

    ADICIONAR(state, contareceber) {
      state.contasreceber.push(contareceber);
    },

    ATUALIZAR(state, contareceber) {
      let index = state.contasreceber.findIndex(contareceber_ => contareceber_.id == contareceber.id);
      if (index > -1) {
        state.contasreceber.splice(index, 1, contareceber);
      }
    },

    EXCLUIR(state, id) {
      let index = state.contasreceber.findIndex(contareceber => contareceber.id == id);
      if (index > -1) {
        state.contasreceber.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.contareceber = {...contareceber};
    }
  },

  actions: {
    async listar({ commit, state }) {

      try {
        commit('LOADING_ON', null, { root: true });
  
        const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;
  
        const paginacao = {
          page, 
          itemsPerPage,
          sortBy: sortBy[0], 
          sortDesc: sortDesc[0]
        }
  
        if (state.busca) {
          paginacao.busca = state.busca;
        }
  
        console.log(paginacao);
        
        let response = await contasReceberApi.listar(paginacao);
        commit('LISTAR', response.data);
        commit('SET_TOTAL_ITENS', response.total);
      } catch (error) {
        throw error;
      } finally {
        commit('LOADING_OFF', null, { root: true });
      }
    },

    async abrir({ commit }, id) {
      try {
        commit('LOADING_ON', null, { root: true });
        let contareceber = await contasReceberApi.abrir(id);
        commit('ABRIR', contareceber);
      } catch (error) {
        throw error;
      } finally {
        commit('LOADING_OFF', null, { root: true });
      }
    },

    async salvar({ commit }, contareceber) {
      let novocontareceber = await contasReceberApi.salvar(contareceber);
      commit('ADICIONAR', novocontareceber);
    },

    async atualizar({ commit }, contareceber) {
      await contasReceberApi.atualizar(contareceber);
      commit('ATUALIZAR', contareceber);
    },

    async excluir({ commit }, id) {
      await contasReceberApi.excluir(id);
      commit('EXCLUIR', id);
    },

    buscarPecaPorCodigo({ commit }, codigo) {
      return contasReceberApi.buscarCodigo(codigo);
    },

  }
}
