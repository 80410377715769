import GuiasLista from '@/pages/movimentacoes/guias/GuiasLista'
import GuiasForm from '@/pages/movimentacoes/guias/GuiasForm'

export default [
  {
    path: '/movimentacoes/guias', 
    name: 'GuiasLista', 
    component: GuiasLista,
    meta: {layout: 'principal', permissao: 'guias_acessar'}
  },
  
  {
    path: '/movimentacoes/guias/adicionar', 
    name: 'GuiasAdicionar', 
    component: GuiasForm,
    meta: {layout: 'principal', permissao: 'guias_adicionar'}
  },
  
  {
    path: '/movimentacoes/guias/:id/editar', 
    name: 'GuiasEditar', 
    component: GuiasForm,
    meta: { layout: 'principal', permissao: 'guias_editar'}
  }
];
