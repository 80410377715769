<template>
  <div>
    <v-card tile class="mb-6">
      <v-card-title class="text-subtitle-2">
        Parceiros
        <v-spacer></v-spacer>
        <v-btn class="ma-0" rounded small color="primary" @click="adicionarParceiro">
            <v-icon left>mdi-plus</v-icon>
          Associar Parceiro
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-data-table 
          mobile-breakpoint="0" 
          hide-default-footer 
          disable-pagination 
          disable-sort_ 
          :headers="headers" 
          :items="empresa.parceiros"
        >
          
          <template v-slot:item="{ item: parceiro, index }">
            <tr>
              <td>{{ parceiro.nome }}</td>
              <td>{{ tipo(parceiro.comissao.tipo_guia) }}</td>
              <td class="text-center">{{ parceiro.comissao.data_limite | dataBR }}</td>
              <td class="text-right">{{ parceiro.comissao.percentual_comissao }}%</td>
              <td class="text-center">
                <v-btn icon @click="editar(parceiro, index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <z-delete
                  titulo="Desassociar parceiro"
                  botaoSim="Desassociar"
                  botaoNao="Cancelar"
                  cor-botao-sim="red"
                  @click:sim="confirmarDesassociar(parceiro, index)"
                  :loading="desassociando"
                >
                  O parceiro <b>{{ parceiro.nome }}</b> será desassociado dessa empresa. Deseja desassociar?
                </z-delete>
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>

    <dialogo-buscar-parceiros ref="dialogoBuscarParceiros" @busca:selecionar="selecionarParceiro"></dialogo-buscar-parceiros>
    <DialogoComissaoParceiro 
      ref="dialogoComissaoParceiro" 
      :adicionando="adicionando" 
      @cancelar="cancelarEditar"
    ></DialogoComissaoParceiro>
    
  </div>
</template>

<script>
  import { mapActions, mapMutations } from "vuex";

  import DialogoBuscarParceiros from "./DialogoBuscarParceiros";
  import DialogoComissaoParceiro from "./DialogoComissaoParceiro";
  import empresasApi from "@/app/services/empresasApi";

  export default {
    name: 'EmpresasParceiros',

    components: {
      DialogoBuscarParceiros,
      DialogoComissaoParceiro,
    },

    props: {
      empresa: {
        type: Object,
        default: () => {}
      },
    },

    data() {
      return {
        headers: [
          { text: 'Parceiro', value: 'nome' },
          { text: 'Tipo Guia', value: 'parceiro.comissao.tipo_guia' },
          { text: 'Data Limite', value: 'data_limite', align: 'center' },
          { text: 'Comissão', value: 'parceiro.comissao.percentual_comissao', align: 'right' },
          { text: 'Opções', value: '', width: '200px', align: 'center' },
        ],
        desassociando: false,
        adicionando: true,
        old_parceiro: {
          index: null,
          parceiro: {}
        }
      }
    },

    computed: {
      name() {
        return this.data;
      },

      tiposGuiasFlat() {

        const new_obj = {};
        this.$globals.TIPOS_GUIAS.forEach(tipo => {
          new_obj[tipo.value] = tipo.text; 
        });

        return new_obj;
      }
    },

    methods: {
      ...mapMutations('empresas', ['SET_PARCEIRO']),
      ...mapActions('empresas', ['removerParceiro']),

      tipo(value) {
        return !!value ? this.tiposGuiasFlat[value] : '';
      },

      adicionarParceiro() {
        // this.$refs.dialogoBuscarParceiros.abrir();
        this.old_parceiro = {
          index: null,
          parceiro: {}
        }
        this.$refs.dialogoComissaoParceiro.abrir();
        this.adicionando = true;
      },

      async selecionarParceiro(parceiro) {
        if (!this.jaExiste(this.empresa.parceiros, parceiro.id)) {
          await empresasApi.adicionarParceiro({
            empresa_id: this.empresa.id, 
            parceiro_id: parceiro.id,
          });
          this.empresa.parceiros.push(parceiro);
        }
      },

      jaExiste(itens, id) {
        const index = itens.findIndex(item => item.id === id);
        return index > -1 ? true : false;
      },

      async confirmarDesassociar(parceiro, index) {
        try {
          this.desassociando = true;
          await empresasApi.removerParceiro(parceiro.comissao.id);

          // let index = this.empresa.parceiros.findIndex(parceiro_ => parceiro_.id == parceiro.id);
          // if (index > -1) {
            this.empresa.parceiros.splice(index, 1);
          // }

          this.$eventbus.notificar("Parceiro desassociado com sucesso!");
        } catch (error) {
          this.$eventbus.notificar("Não foi possível desassociar o parceiro!");
          throw error;
        } finally {
          this.desassociando = false;
        }
      },

      editar(parceiro, index) {
        this.adicionando = false;
        this.SET_PARCEIRO(parceiro.comissao);
        this.old_parceiro.index = index;
        this.old_parceiro.parceiro = this.clone(parceiro);
        this.$refs.dialogoComissaoParceiro.abrir();
      },

      cancelarEditar() {
        if (this.old_parceiro.index != null && this.old_parceiro.index > -1 ) {
          this.empresa.parceiros.splice(this.old_parceiro.index, 1, this.old_parceiro.parceiro);
          this.old_parceiro = {
            index: null,
            parceiro: {}
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>