<template>
  <z-dialogo 
    v-bind="$attrs" 
    ref="dialogo" 
    scrollable
    largura="1200px"
    :dividers="true"
  >
    <template #titlebuttons>
      <v-btn icon @click="$refs.dialogo.fechar()" class="ma-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template #texto>
      <v-row dense>
        <v-col cols="12">
          <z-loading height="500px" :loading="loading"></z-loading>

          <div v-if="open">
            <iframe
              v-show="!loading"
              id="print_frame" 
              name="print_frame"
              ref="print_frame"
              :src="url + pdfOptions"
              @load="onLoadFrame" 
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </template>

    <template #actions_left>
      <v-btn text @click="$refs.dialogo.fechar()">
        Fechar
      </v-btn>
    </template>
    
    <template #actions_right>
      <v-btn text color="primary" @click="download" :disabled="loading">
        <v-icon left>mdi-download</v-icon>
        Download
      </v-btn>
      <v-btn color="primary" @click="imprimir" :disabled="loading">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>
    </template>
  </z-dialogo>
</template>

<script>

import { abrirJanela } from '@/app/helpers/utils';
export default {
  name: 'zDialogoImpressao',

  props: {
    urlImpressao: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      open: false,
      pdfOptions: '#zoom=100&scrollbar=0&toolbar=0&navpanes=0',
      // pdfOptions: '#view=fitH&zoom=50&scrollbar=0&toolbar=0&navpanes=0',
      loading: false,
      url: '',
      paisagem: false
    };
  },

  mounted () {

  },

  methods: {
    abrir(opcoes = {}) {
      this.paisagem = opcoes.paisagem || false;
      this.$refs.dialogo.abrir();
    },

    fechar() {
      this.paisagem = false;
      this.open = false;
      this.url = "";
      this.$refs.dialogo.fechar();
      this.$emit('fechar');
    },

    imprimir() {

      // setTimeout(function(){
        window.frames["print_frame"].focus();
        window.frames["print_frame"].print();
      // },2000);
      
      this.$emit('imprimir');
    },

    download() {
      abrirJanela(this.urlImpressao + '&download=true');
      // window.location.href = this.urlImpressao + '&download=true';
    },

    onLoadFrame() {
      this.loading = false;
    },

    loadIFrameURL(url) {
      this.open = false;
      this.loading = true;
      setTimeout(() => {
        this.url = url;
        this.open = true;
      }, 0);
    },

    // unloadIFrame() {
    //   var iframe = document.getElementById("print_frame");
    //   var html = "";

    //   iframe.contentWindow.document.open();
    //   iframe.contentWindow.document.write(html);
    //   iframe.contentWindow.document.close();
    //   window.frames["print_frame"].document.close()
    // }
  },

  watch: {
    urlImpressao(newValue, oldValue) {
      this.loadIFrameURL(newValue);
    }
  },
};
</script>

<style scoped>
  #print_frame {
    /* border: 1px solid red; */
    width: 100% !important;
    height: 500px !important;
    /* border-style: none !important; */
  }
</style>
