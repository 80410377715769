import itensApi from "@/app/services/itensApi";
import item from "@/app/store/models/item";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    item: {...item},
    itens: [
      { id: 1, descricao: 'Descrição 1' },
      { id: 2, descricao: 'Descrição 2' },
      { id: 3, descricao: 'Descrição 3' },
      { id: 4, descricao: 'Descrição 4' },
    ],
    busca: "",
    total_itens: 4,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, itens) {
      state.itens = itens;
    },

    ABRIR(state, item) {
      state.item = item;
    },

    ADICIONAR(state, item) {
      state.itens.push(item);
      state.item = item;
    },

    ATUALIZAR(state, item) {
      let index = state.itens.findIndex(item_ => item_.id == item.id);
      if (index > -1) {
        state.itens.splice(index, 1, item);
      }
    },

    EXCLUIR(state, id) {
      let index = state.itens.findIndex(item => item.id == id);
      if (index > -1) {
        state.itens.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.item = {...item};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await itensApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit, state }, id) {
      commit('LOADING_ON', null, { root: true });
      // let item = await itensApi.abrir(id);
      commit('ABRIR', state.itens[0]);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, item) {
      // let item = await itensApi.salvar(item);
      // commit('ADICIONAR', item);
    },

    async atualizar({ commit }, item) {
      // await itensApi.atualizar(item);
      // commit('ATUALIZAR', item);
    },

    async excluir({ commit }, id) {
      // await itensApi.excluir(id);
      // commit('EXCLUIR', id);
    },

  }
}
