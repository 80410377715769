
import listasApi from "@/app/services/listasApi";
import { defineStore } from 'pinia'

import { useGlobalStore } from '@/app/store/stores/global'

export const useListasStore = defineStore('listas', {

  state: () => ({
    empresas: [],
    parceiros: [],
    perfis: [],
  }),

  getters: {
    listaEmpresas() {
      return this.empresas.map(empresa => {
        return {
          descricao: empresa.id + " - " +empresa.razao,
          id: empresa.id,
          regime_tributario: empresa.regime_tributario.charAt(0).toUpperCase() + empresa.regime_tributario.slice(1),
          percentual_desconto: empresa.percentual_desconto
        };
      });
    },

    listaParceiros() {
      return this.parceiros.map(parceiro => {
        return {
          descricao: parceiro.id + " - " + parceiro.nome,
          id: parceiro.id,
          percentual_desconto: parceiro.percentual_desconto
        };
      });
    },

    listaPerfis() {
      return this.perfis.map(perfil => {
        return {
          text: perfil.descricao,
          value: perfil.id,
        };
      });
    },
  },

  actions: {
    async todas() {
      this.empresas();
      this.parceiros();
      this.perfis();
    },

    async getEmpresas() {
      this.empresas = await listasApi.empresas();
    },

    async getParceiros() {
      this.parceiros = await listasApi.parceiros();
    },

    async getPerfis() {
      this.perfis = await listasApi.perfis();
    },

  },

});


