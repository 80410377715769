import EmpresasLista from '@/pages/cadastros/empresas/EmpresasLista'
import EmpresasForm from '@/pages/cadastros/empresas/EmpresasForm'

export default [
  {
    path: '/cadastros/empresas', 
    name: 'EmpresasLista', 
    component: EmpresasLista,
    meta: {layout: 'principal', permissao: 'empresas_acessar'}
  },
  
  {
    path: '/cadastros/empresas/adicionar', 
    name: 'EmpresasAdicionar', 
    component: EmpresasForm,
    meta: {layout: 'principal', permissao: 'empresas_adicionar'}
  },
  
  {
    path: '/cadastros/empresas/:id/editar', 
    name: 'EmpresasEditar', 
    component: EmpresasForm,
    meta: { layout: 'principal', permissao: 'empresas_editar'}
  }
];
