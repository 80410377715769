import { ref, computed } from "vue";
import { storeToRefs } from 'pinia'


export function useAlerta(eventsStore) {


  // const alerta = computed(() => eventsStore.alerta);
  
  const { alerta, testeS } = storeToRefs(eventsStore);

  // console.log(eventsStore.testeS);

  // console.log(eventsStore.p());


  return {
    alerta
  }
}