import PadraoLista from '@/pages/layout/padrao/PadraoLista'
import PadraoForm from '@/pages/layout/padrao/PadraoForm'

export default [
  {
    path: '/layout/padrao', 
    name: 'PadraoLista', 
    component: PadraoLista,
    meta: {layout: 'principal', permissao: 'padrao_acessar'}
  },
  
  {
    path: '/layout/padrao/adicionar', 
    name: 'PadraoAdicionar', 
    component: PadraoForm,
    meta: {layout: 'principal', permissao: 'padrao_adicionar'}
  },
  
  {
    path: '/layout/padrao/:id/editar', 
    name: 'PadraoEditar', 
    component: PadraoForm,
    meta: { layout: 'principal', permissao: 'padrao_editar'}
  }
];
