export const APP_ICON = 'mdi-file-document-check-outline'

export const REFRESH = 'mdi-refresh';

export const ACESSAR = 'mdi-lock-open';
export const ADICIONAR = 'mdi-plus';
export const EDITAR = 'mdi-pencil';
export const EXCLUIR = 'mdi-delete';
export const IMPRIMIR = 'mdi-printer';
export const FINALIZAR = 'mdi-arrow-down';
export const SALVAR = 'mdi-content-save';
export const CANCELAR = 'mdi-cancel';
export const VOLTAR = 'mdi-arrow-left';
export const FILTRAR = 'mdi-filter-variant';
export const CALENDARIO = 'mdi-calendar';
export const ARQUIVOS = 'mdi-file';
export const ANEXOS = 'mdi-paperclip';

export const CADASTROS = 'mdi-account';
export const TABELAS = 'mdi-table';
export const MOVIMENTACOES = 'mdi-swap-horizontal';
export const CONSULTAS = 'mdi-filter';
export const SISTEMA = 'mdi-cog';

export const EMPRESAS = 'mdi-card-account-details';
export const PARCEIROS = 'mdi-wallet-travel';
export const GUIAS = 'mdi-notebook';
export const RELATORIOS = 'mdi-clipboard-list-outline';
export const CONTAS_RECEBER = 'mdi-arrow-bottom-right-thick';
export const CONTAS_PAGAR = 'mdi-arrow-top-right-thick';


export const BANCOS = 'mdi-bank';
export const TIPOS_CLIENTES = 'mdi-account-cog';
export const TIPOS_FUNCIONARIOS = 'mdi-card-account-details-outline';
export const TIPOS_OS = 'mdi-clipboard-list-outline';
export const GRUPOS_PECAS = 'mdi-format-list-bulleted-type';
export const PRAZOS_PAGAMENTOS = 'mdi-calendar-clock';
export const FORMAS_PAGAMENTOS = 'mdi-cash-usd-outline';
export const MONEY = 'mdi-currency-usd';

export const FINANCEIRO = 'mdi-currency-usd-circle';

export const ORDENS_SERVICO = 'mdi-clipboard-list-outline';
export const MOVIMENTO_ESTOQUE = 'mdi-swap-horizontal-circle-outline';

export const USUARIOS = 'mdi-card-account-details';
export const PERMISSOES = 'mdi-format-list-checkbox';

