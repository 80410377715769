import ContasPagarLista from '@/pages/movimentacoes/contaspagar/ContasPagarLista'
// import ContasReceberForm from '@/pages/movimentacoes/contaspagar/ContasPagarLista'

export default [
  {
    path: '/movimentacoes/contaspagar', 
    name: 'ContasPagarLista', 
    component: ContasPagarLista,
    meta: {layout: 'principal', permissao: 'contaspagar_acessar'}
  },
  
  // {
  //   path: '/movimentacoes/contaspagar/adicionar', 
  //   name: 'ContasReceberAdicionar', 
  //   component: ContasReceberForm,
  //   meta: {layout: 'principal', permissao: 'empresas_adicionar'}
  // },
  
  // {
  //   path: '/movimentacoes/contaspagar/:id/editar', 
  //   name: 'ContasReceberEditar', 
  //   component: ContasReceberForm,
  //   meta: { layout: 'principal', permissao: 'empresas_editar'}
  // }
];
