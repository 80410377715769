export default {
  user_id: null,
  parceiro_id: null,
  empresa_id: null,
  percentual_desconto: 0,
  tipo_guia: 'SIMPLES',
  data_recebimento_guia: '',
  valor_guia: 0,
  deducao_terceiros: 0,
  valor_iss_icms: 0,
  valor_a_compensar: 0,
  deducao: 0,
  valor_a_pagar: 0,
  codigo_deposito_judicial: '',
  vencimento_boleto: '',
  data_recebimento_boleto: null,
  valor_transferencia: 0,
  data_transferencia: '',
  competencia_ano: (new Date()).getFullYear(),
  competencia_mes: (new Date()).getMonth() + 1
}


