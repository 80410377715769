import createCrudApi from "@/app/services/baseApi";
const contasPagarApi = createCrudApi('contaspagar');

const { axios } = contasPagarApi;

contasPagarApi.pagar = function(contaspagar, data_transferencia) {
  return axios.post(`contaspagar/${contaspagar.id}/pagar`, { data_transferencia: data_transferencia }).then((response) => response.data);
}

contasPagarApi.cancelarPagamento = function(contaspagar) {
  return axios.delete(`contaspagar/${contaspagar.id}/cancelarpagamento`).then((response) => response.data);
}

export default contasPagarApi;
