<template>
  <v-toolbar flat v-bind="$attrs">
    <v-container :fluid="fluid" py-0>
      <v-row dense :justify="justify">
        <v-col :cols="_cols.cols" class="v-toolbar__content" style="height: 64px;">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-linear
        :active="loading"
        :indeterminate="true"
        absolute
        bottom
        color="primary"
        height="1"
      ></v-progress-linear>
  </v-toolbar>
</template>

<script>
import { mapStores } from 'pinia';
import { useGlobalStore } from '@/app/store/stores/global';


  export default {
    name: 'z-centralized-toolbar',
    
    props: {
      fluid: {
        type: Boolean,
        default: false
      },

      justify: {
        type: String,
        default: "center",
      },

      cols: {
        type: [String, Number],
        default: "9",
      },
    },

    computed: {
      ...mapStores(useGlobalStore),
      loading() {
        return this.$store.state.loading || this.globalStore.loading;
      },

      isHD() {
        return this.$vuetify.breakpoint.width <= 1440;
      },

      _cols() {
        if (this.isHD || this.fluid) {
          return {
            cols: 12,
            fluid: true
          }
        }

        return {
          cols: this.cols,
          fluid: false
        }
      },

      isFluid() {
        return this.$vuetify.breakpoint.lgAndDown;
      },
    },
  }
</script>

<style scoped>

</style>