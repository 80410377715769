<template>
  <div>
    <DrawerFiltroReceber v-model="filtroChips" ref="filtroContasReceber" @input="refresh"></DrawerFiltroReceber>

    <z-centralized-toolbar cols="10" fluid>
      <v-toolbar-items>
        <v-btn text small color="primary" @click="filtrar">
          <v-icon left>{{ $icon.FILTRAR }}</v-icon>Filtrar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          :loading="loading"
          @click="refresh"
        >
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>

      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>

    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container cols="10">

        <zCabecalhoWithActions>
          {{ globalStore.titulo_tela }}

          <!-- <template v-slot:actions>
            <v-btn text @click="filtrar">
              <v-icon left>{{ $icon.FILTRAR }}</v-icon>Filtrar
            </v-btn>

            <v-btn
              icon
              id="btn-atualizar"
              :loading="loading"
              @click="refresh"
            >
              <v-icon>{{ $icon.REFRESH }}</v-icon>
              <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
            </v-btn>
          </template> -->
        </zCabecalhoWithActions>

        <v-card>
          <v-card-text v-if="filtroChips.length > 0">
            <div class="d-flex" style="gap: 10px; flex-wrap: wrap;">
              <v-chip
                v-for="(chip, index) in filtroChips"
                close
                :key="index"
                @click:close="$refs.filtroContasReceber.removerFiltro(chip)"
              >
                {{ chip.descricao }}
              </v-chip>
            </div>
          </v-card-text>
          <v-divider v-if="filtroChips.length > 0"></v-divider>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="contasreceber"
            item-key="id"
          >
            <template v-slot:item="{ item: contareceber }">
              <tr>
                <td>
                  <a class="text-link" @click.prevent="irParaGuiasEmpresa(contareceber.guia.empresa, contareceber.guia.id)">
                    {{ contareceber.guia.id }}
                  </a>
                </td>
                <td>
                  <a class="text-link" @click.prevent="irParaGuiasEmpresa(contareceber.guia.empresa)">
                    {{ contareceber.guia.empresa.razao }}
                  </a>
                </td>
                <td>{{ contareceber.codigo_deposito_judicial }}</td>
                <td class="text-center">{{ contareceber.vencimento_boleto | dataBR }}</td>
                <td class="text-center">{{ contareceber.guia.competencia }}</td>
                <td class="text-right font-weight-bold">{{ contareceber.valor_a_pagar | valorBR }}</td>
                <td class="text-center">
                  <template v-if="contareceber.data_recebimento_boleto">
                    {{ contareceber.data_recebimento_boleto | dataBR }}
                  </template>
                  <v-chip v-else color="red" dark small>Em Aberto</v-chip>
                </td>
                <td class="text-center">
                  <v-btn
                    small
                    depressed
                    color="primary"
                    @click="darBaixa(contareceber)"
                  >
                    {{ !contareceber.recebido ? 'Receber' : 'Ver' }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <dialogo-dar-baixa ref="dialogoDarBaixa"></dialogo-dar-baixa>
  </div>
</template>

<script>
// import { mapMutations, mapActions, mapState } from "vuex";
import { mapActions, mapStores, mapWritableState } from 'pinia';
import { useContasReceberStore, useGlobalStore } from '@/app/store/stores';

import DialogoDarBaixa from "./DialogoDarBaixa";
import DrawerFiltroReceber from "./DrawerFiltroReceber";

export default {
  name: "ContasReceberLista",

  components: {
    DialogoDarBaixa,
    DrawerFiltroReceber
  },

  data() {
    return {
      titulo_tela: 'Contas a Receber - Empresas',
      headers: [
        { text: "Guia", value: "guia.id", width: "100px" },
        { text: "Empresa", value: "guia.empresa.razao" },
        { text: "Código Depósito", value: "codigo_deposito_judicial" },
        { text: "Vencimento Boleto", value: "vencimento_boleto", align: "center" },
        { text: "Competência", align: "center" },
        { text: "Valor a Receber", value: "valor_transferencia", align: "right" },
        { text: "Data Recebimento ", value: "data_recebimento_boleto", align: "center" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      empresa_codigo: "",
      filtroChips: []
    };
  },

  computed: {
    ...mapStores(useContasReceberStore, useGlobalStore),
    ...mapWritableState(useContasReceberStore, ["contasreceber", "contareceber", "total_itens", 'paginacao', 'busca']),
    ...mapWritableState(useGlobalStore, ["loading"]),
    filtroGuias() {
      return this.$store.state.guias.filtro;
    },

    titulo() {
      return global.titulo_tela
    }
  },

  created() {
    this.refresh();
  },

  mounted() {
    const global = useGlobalStore();
    this.setTitulo(this.titulo_tela);
  },

  methods: {
    ...mapActions(useContasReceberStore, ['listar', 'excluir', 'abrir']),
    ...mapActions(useGlobalStore, ['setTitulo', 'LOADING_OFF']),

    teste() {
      global.loading = !global.loading;
      global.setTitulo("Teste: " + global.loading);
    },

    async refresh() {
      try {

        let filtro = {}

        this.filtroChips.forEach(item => {
          filtro[item.chave] = item.valor;
        });

        await this.listar(filtro);
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível listar as contasreceber.", error);
      }
    },

    buscar() {
      this.contasreceberStore.SET_PAGE(1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.contasreceberStore.SET_PAGE(1);
      this.refresh();
    },

    async darBaixa(contareceber) {
      try {
        await this.abrir(contareceber.id);
        this.$refs.dialogoDarBaixa.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a conta a receber.", error);
      }
    },

    filtrar() {
      this.$refs.filtroContasReceber.abrir();
    },

    imprimir() {
      
    },

    irParaGuiasEmpresa(empresa, guia = null) {
      this.filtroGuias.empresa_id = empresa.id;
      this.filtroGuias.busca = guia || null;
      this.$router.push({name: 'GuiasLista'})
    }
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
