import guiasApi from "@/app/services/guiasApi";
import guia from "@/app/store/models/guia";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    guia: {...guia},
    guias: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS,
    filtro: {
      busca: null,
      empresa_id: null,
      ano: new Date().getFullYear(),
      mes: null //new Date().getMonth() + 1,
    },
  },

  mutations: {
    LISTAR(state, guias) {
      state.guias = guias;
    },

    ABRIR(state, guia) {
      state.guia = guia;
    },

    ADICIONAR(state, guia) {
      state.guias.push(guia);
    },

    ATUALIZAR(state, guia) {
      let index = state.guias.findIndex(guia_ => guia_.id == guia.id);
      if (index > -1) {
        state.guias.splice(index, 1, guia);
      }
    },

    EXCLUIR(state, id) {
      let index = state.guias.findIndex(guia => guia.id == id);
      if (index > -1) {
        state.guias.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.guia = {...guia};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0],
        ...state.filtro
      }

      // if (state.filtro.busca) {
      //   paginacao.busca = state.filtro.busca;
      // }

      let response = await guiasApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let guia = await guiasApi.abrir(id);
      commit('ABRIR', guia);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, guia) {
      let novoguia = await guiasApi.salvar(guia);
      commit('ADICIONAR', novoguia);
    },

    async atualizar({ commit }, guia) {
      await guiasApi.atualizar(guia);
      commit('ATUALIZAR', guia);
    },

    async compensar({ commit }, guia) {
      const response = await guiasApi.compensar(guia);
      commit('ATUALIZAR', response);
    },

    async cancelarCompensacao({ commit }, guia) {
      const response = await guiasApi.cancelarCompensacao(guia);
      commit('ATUALIZAR', response);
    },

    async excluir({ commit }, id) {
      await guiasApi.excluir(id);
      commit('EXCLUIR', id);
    },

    buscarPecaPorCodigo({ commit }, codigo) {
      return guiasApi.buscarCodigo(codigo);
    },

  }
}
