import createCrudApi from "@/app/services/baseApi";
const guiasApi = createCrudApi('guias');

const { axios, url } = guiasApi;

guiasApi.compensar = function (guia) {
  return axios.put(`${url}/${guia.id}/compensar`, guia).then((response) => response.data);
}

guiasApi.cancelarCompensacao = function (guia) {
  return axios.delete(`${url}/${guia.id}/cancelarcompensacao`, guia).then((response) => response.data);
}

export default guiasApi;
