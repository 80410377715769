export default {

  props: {
    configOff: {
      type: Boolean,
      default: false,
    },
  },
  
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },
}