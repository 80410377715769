import empresasApi from "@/app/services/empresasApi";
import empresa from "@/app/store/models/empresa";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

export default {
  namespaced: true,
  state: {
    empresa: {...empresa},
    parceiro: {
      parceiro_id: null,
      empresa_id: null,
      percentual_comissao: 0,
      data_limite: '',
      tipo_guia: ''
    },
    empresas: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS,
    filtro: {
      busca: null,
      paginacao: PAGINATION_OPTIONS
    }
  },

  mutations: {
    LISTAR(state, empresas) {
      state.empresas = empresas;
    },

    ABRIR(state, empresa) {
      state.empresa = empresa;
    },

    ADICIONAR(state, empresa) {
      state.empresas.push(empresa);
    },

    ATUALIZAR(state, empresa) {
      let index = state.empresas.findIndex(empresa_ => empresa_.id == empresa.id);
      if (index > -1) {
        state.empresas.splice(index, 1, empresa);
      }
    },

    EXCLUIR(state, id) {
      let index = state.empresas.findIndex(empresa => empresa.id == id);
      if (index > -1) {
        state.empresas.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.empresa = {...empresa};
    },

    ZERAR_PARCEIRO(state) {
      state.parceiro = {
        parceiro_id: null,
        empresa_id: null,
        percentual_comissao: 0,
        data_limite: '',
        tipo_guia: ''
      }
    },

    ADICIONA_PARCEIRO(state, response) {
      state.empresa.parceiros.push({
        id: response.parceiro_id,
        nome: response.parceiro_nome,
        comissao: {
          id: response.id,
          parceiro_id: response.parceiro_id,
          empresa_id: response.empresa_id,
          percentual_comissao: response.percentual_comissao,
          data_limite: response.data_limite,
          tipo_guia: response.tipo_guia,
        }
      })
    },

    ATUALIZAR_PARCEIRO(state, parceiro) {
      const parceiro_ = state.empresa.parceiros.find(parceiro_ => parceiro_.id == parceiro.parceiro_id);
      parceiro_.comissao.percentual_comissao = parceiro.percentual_comissao;
      parceiro_.comissao.data_limite = parceiro.data_limite;
    },

    SET_PARCEIRO(state, parceiro) {
      state.parceiro = parceiro;
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.filtro.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.filtro.busca) {
        paginacao.busca = state.filtro.busca;
      }

      let response = await empresasApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let empresa = await empresasApi.abrir(id);
      commit('ABRIR', empresa);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, empresa) {
      let novoempresa = await empresasApi.salvar(empresa);
      commit('ADICIONAR', novoempresa);
    },

    async atualizar({ commit }, empresa) {
      await empresasApi.atualizar(empresa);
      commit('ATUALIZAR', empresa);
    },

    async excluir({ commit }, id) {
      await empresasApi.excluir(id);
      commit('EXCLUIR', id);
    },
    
    buscarPecaPorCodigo({ commit }, codigo) {
      return empresasApi.buscarCodigo(codigo);
    },
    
    async salvarParceiro({ commit }, parceiro) {
      let response = null;

      if (parceiro.adicionando) {
        response = await empresasApi.salvarParceiro(parceiro);
        commit('ADICIONA_PARCEIRO', response);
      } else {
        response = await empresasApi.atualizarParceiro(parceiro);
        //   commit('ATUALIZAR_PARCEIRO', parceiro);
      }

    },

    async removerParceiro({ commit }, parceiro) {
      await empresasApi.removerParceiro(parceiro);
    }

  }
}
