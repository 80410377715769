<template>
  <v-btn id="btRelatorios" text small>
    <v-icon left>mdi-printer</v-icon>
    Imprimir
    <v-icon right>mdi-menu-down</v-icon>

    <v-menu bottom offset-y transition="slide-y-transition" activator="#btRelatorios">
      <!-- <template v-slot:activator="{ on, attrs }">
      </template> -->

      <v-list nav dense>
        <v-subheader class="text-uppercase text--primary font-weight-black">Imprimir</v-subheader>
        <template v-for="(impressao, index) in itens">
          <v-list-item :key="index" @click="emitir(impressao.acao)" v-show="impressao.visible">
            <v-list-item-icon>
              <v-icon>mdi-card-text-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ impressao.texto }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>
export default {
  name: 'MenuImprimir',

  props: {
    itens: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      // impressoes: [
      //   { texto: 'Caixa do Dia', acao: 'imprimir_caixa_dia', visible: true },
      //   { texto: 'Caixa Mensal', acao: 'imprimir_caixa_mensal', visible: true },
      // ],
    };
  },

  methods: {
    emitir(relatorio) {
      this.$emit('abrir:relatorio', relatorio);
    },
  },
};
</script>

<style scoped></style>
