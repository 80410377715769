import ContasReceberLista from '@/pages/movimentacoes/contasreceber/ContasReceberLista'
// import ContasReceberForm from '@/pages/movimentacoes/contasreceber/ContasReceberLista'

export default [
  {
    path: '/movimentacoes/contasreceber', 
    name: 'ContasReceberLista', 
    component: ContasReceberLista,
    meta: {layout: 'principal', permissao: 'contasreceber_acessar'}
  },
  
  // {
  //   path: '/movimentacoes/contasreceber/adicionar', 
  //   name: 'ContasReceberAdicionar', 
  //   component: ContasReceberForm,
  //   meta: {layout: 'principal', permissao: 'empresas_adicionar'}
  // },
  
  // {
  //   path: '/movimentacoes/contasreceber/:id/editar', 
  //   name: 'ContasReceberEditar', 
  //   component: ContasReceberForm,
  //   meta: { layout: 'principal', permissao: 'empresas_editar'}
  // }
];
