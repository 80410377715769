<template>
  <h2
    class="text-body-1 pa-2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </h2>
</template>

<script>
  export default {
    name: 'z-cabecalho',

    inheritAttrs: false
  }
</script>
