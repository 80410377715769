<template>
  <v-container :fluid="(fluid || isFluid)">
    <v-row dense :justify="justify">
      <v-col :cols="_cols.cols">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "z-container",
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },

    justify: {
      type: String,
      default: "center",
    },

    cols: {
      type: [String, Number],
      default: "9",
    },
  },

  computed: {
    isHD() {
      return this.$vuetify.breakpoint.width <= 1440;
    },

    _cols() {
      if (this.isHD) {
        return {
          cols: 12,
          fluid: true
        }
      }

      return {
        cols: this.cols,
        fluid: false
      }
    },

    isFluid() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
  },
};
</script>

<style scoped></style>
