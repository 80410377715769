import Vue from 'vue'
import Router from 'vue-router'

import PadraoRoutes from './routes/padrao';
import HomeRoutes from './routes/home';
import UsuariosRoutes from './routes/usuarios';
import PublicoRoutes from './routes/publico';

import ParceirosRoutes from './routes/parceiros';
import EmpresasRoutes from './routes/empresas';
import GuiasRoutes from './routes/guias';
import ContasReceberRoutes from './routes/contasreceber';
import ContasPagarRoutes from './routes/contaspagar';
import RelatoriosRoutes from './routes/relatorios';

import { guards } from './guards.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },

  routes: [
    ...PadraoRoutes,
    ...HomeRoutes,
    ...UsuariosRoutes,
    ...PublicoRoutes,

    ...ParceirosRoutes,
    ...EmpresasRoutes,
    ...GuiasRoutes,
    ...ContasReceberRoutes,
    ...ContasPagarRoutes,
    ...RelatoriosRoutes
  ]
})

router.beforeEach(guards);

export default router;