export default {
  user_id: null,
  data_cadastro: '',
  cpf_cnpj: '',
  nome: '',
  contato: '',
  telefone_fixo: '',
  telefone_celular: '',
  email: '',
  percentual_desconto: ''
}