<template>
  <v-text-field
    v-bind="attributes"
    v-on="$listeners"
    ref="input"
    @input.native="onInput"
    @focus="onFocus"
    @blur="onBlur"
  ></v-text-field>
</template>

<script>
export default {
  name: "z-text-field",

  props: {
    upper: {
      type: Boolean,
      default: false,
    },
    lower: {
      type: Boolean,
      default: false,
    },
    capitalized: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    normal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    attributes() {
      // if (this.normal) {
      //   return this.$attrs;
      // }

      return { ...this.$attrs, ...this.$v.TEXT_FIELDS };
    },

    listeners() {
      if (this.normal) {
        return this.$listeners;
      }
      return null;
    },
  },

  methods: {
    onInput(event) {
      if (this.upper || this.lower) {
        let posicao = event.target.selectionStart; // Marca a posição do cursor
        event.target.value = this.transforma(event.target.value);
        event.target.setSelectionRange(posicao, posicao); // Restaura a posição do cursor
      }

      this.$emit("input", event.target.value);
    },

    onFocus(event) {
      if (this.select) {
        setTimeout(function () {
          event.target.select();
        }, 0);
      }
    },

    onBlur(event) {
      if (this.capitalized && !this.lower && !this.upper) {
        this.$emit("input", this.capitalize(event.target.value));
      }
    },

    transforma(texto = "") {
      if (this.upper && !this.lower && !this.capitalized) {
        return texto.toUpperCase();
      }

      if (this.lower && !this.upper && !this.capitalized) {
        return texto.toLowerCase();
      }

      return texto;
    },

    capitalize(texto) {
      const regex = /^(?=.*[áéíóúâêîôûàèìòùãõàèìòùäëïöü])/;
      let capitalized = texto
        .trim()
        .toLowerCase()
        .split(" ")
        .map((word) => {
          if (["da", "das", "de", "do", "dos"].includes(word)) {
            return word;
          }

          if (word.length == 1 && regex.test(word)) {
            return word;
          }

          return word.charAt(0).toUpperCase() + word.slice(1, word.length);
        });

      return capitalized.join(" ");
    },

    focus(timeout=0) {
      setTimeout(() => {
        this.$refs.input.focus();
      }, timeout);
    },
  },
};
</script>

<style scoped>
</style>