export default {
  guia_id: null,
  parceiro_id: null,
  guia: {
    empresa:{
      razao: ''
    }
  },
  parceiro:{
    nome: ''
  },
  data_pagamento: null,
  valor_transferencia: 0,
  data_transferencia: null,
}