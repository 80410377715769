<template>
  <div>
    <z-container cols="10">
      <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

      <v-form ref="formulario">
        <v-card>
          <v-toolbar flat color="white" dense>
            <h3 class="text-subtitle-2">Filtro</h3>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            
            <v-row dense>
              <v-col cols="6">
                <label class="label" for="">
                  Parceiro
                  <z-autocomplete
                    v-model="filtro.parceiro_id"
                    label="Parceiro"
                    item-value="id"
                    item-text="descricao"
                    single-line
                    clearable
                    hide-details
                    :items="parceiros"
                    @change="limparGuias"
                    @input="(value) => filtro.parceiro_id = value === null ? '' : value"
                  ></z-autocomplete>
                </label>
              </v-col>
              <v-col cols="6">
                <label class="label" for="">
                  Empresa
                  <z-autocomplete
                    v-model="filtro.empresa_id"
                    label="Empresa"
                    item-value="id"
                    item-text="descricao"
                    single-line
                    clearable
                    hide-details
                    :items="empresas"
                    @change="limparGuias"
                    @input="(value) => filtro.empresa_id = value === null ? '' : value"
                  ></z-autocomplete>
                </label>
              </v-col>
              <v-col cols="2">
                <label class="label" for="">
                  Tipo de Guia
                  <v-select
                    v-model="filtro.tipo_guia"
                    v-bind="$v.TEXT_FIELDS"
                    label="Tipo de Guia"
                    single-line
                    clearable
                    hide-details
                    multiple
                    attach
                    chips
                    :items="['SIMPLES', 'DARF_INSS', 'DARF_PIS', 'DARF_COFINS', 'DARF_CSLL', 'DARF_IRPJ']"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption" >
                        (+{{ filtro.tipo_guia.length - 1 }})
                      </span>
                    </template>
                  </v-select>
                </label>
              </v-col>
              <v-col cols="2">
                <label class="label" for="">
                  Situação
                  <z-select
                    v-model="filtro.status"
                    single-line
                    hide-details
                    label="Situação"
                    :items="status"
                    @change="limparGuias"
                  ></z-select>
                </label>
              </v-col>

              <v-col cols="4">
                <v-row dense>
                  <v-col cols="6">
                    <label class="label" for="">
                      Competência Inicial
                      <z-select
                        v-model="filtro.mes_inicial"
                        label="Meses"
                        single-line
                        hide-details
                        :items="$globals.MESES"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                  
                  <v-col cols="6">
                    <label class="label" for="">
                      &nbsp;
                      <z-select
                        v-model="filtro.ano_inicial"
                        label="Anos"
                        single-line
                        hide-details
                        :items="anos"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                </v-row>
              
              </v-col>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="6">
                    <label class="label" for="">
                      Competência Final
                      <z-select
                        v-model="filtro.mes_final"
                        label="Meses"
                        single-line
                        hide-details
                        :items="$globals.MESES"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                  
                  <v-col cols="6">
                    <label class="label" for="">
                      &nbsp;
                      <z-select
                        v-model="filtro.ano_final"
                        label="Anos"
                        single-line
                        hide-details
                        :items="anos"
                        @change="limparGuias"
                      ></z-select>
                    </label>
                  </v-col>
                </v-row>
              
              </v-col>
            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <z-menu-button :prepend-icon="$icon.IMPRIMIR" :items="impressoes" text="Imprimir" :disabled="guias.length == 0"></z-menu-button>

            <!-- <v-btn text @click="imprimir" :disabled="guias.length == 0">
              <v-icon left>mdi-printer</v-icon>
              Imprimir
            </v-btn> -->
            <v-btn color="primary" @click="filtrar" :loading="filtrando">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </z-container>

    <z-container cols="10">
      <z-cabecalho>Resultado ({{ guias.length }})</z-cabecalho>

      <v-card min-height="200px">
        <v-card-text class="pa-0">
          <v-data-table 
            mobile-breakpoint="0" 
            hide-default-footer 
            disable-pagination 
            disable-sort 
            :headers="headers" 
            :items="guias"
          >
            <template v-slot:item="{ item: guia, index }">
              <tr>
                <td>{{ guia.id }}</td>
                <td>{{ guia.empresa.razao }}</td>
                <td>{{ guia.tipo_guia }}</td>
                <td class="text-right">{{ guia.valor_guia | valorBR }}</td>
                <td class="text-right font-weight-bold">{{ guia.valor_a_pagar | valorBR }}</td>
                <td class="text-center">{{ guia.competencia_mes + "/" + guia.competencia_ano }}</td>
                <td class="text-center">{{ guia.data_compensacao | dataBR }}</td>
                <td class="text-center">{{ guia.data_recebimento_boleto | dataBR }}</td>
                <td class="text-center">
                  <v-chip v-if="!guia.data_compensacao" color="blue" dark small>A Compensar</v-chip>
                  <v-chip v-else-if="guia.data_recebimento_boleto" color="green" dark small>Pago</v-chip>
                  <v-chip v-else color="red" dark small>A Receber</v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </z-container>
    <div style="margin-bottom: 200px"></div>

    <z-dialogo-impressao 
      ref="dialogoImpressao" 
      titulo="Impressão"
      :url-impressao="urlImpressao"
      @fechar="urlImpressao = ''"
    ></z-dialogo-impressao>


  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { hoje, abrirJanela, range } from '@/app/helpers/utils';


import relatoriosApi from '@/app/services/relatoriosApi';
import config from '@/app/config/app';

import qs from 'qs';

export default {
  name: 'GuiasLancadas',
  data() {
    return {
      filtro: {
        parceiro_id: '',
        empresa_id: '',
        status: '',
        mes_inicial: (new Date).getMonth() + 1,
        ano_inicial: (new Date).getFullYear(),
        mes_final: (new Date).getMonth() + 1,
        ano_final: (new Date).getFullYear(),
        tipo_guia: ''
      },

      status: [
        { value: '', text: 'Qualquer' },
        { value: 'acompensar', text: 'A compensar' },
        { value: 'pago', text: 'Pago' },
        { value: 'areceber', text: 'A Receber' },
      ],


      headers: [
        { text: "Guia", value: "id", width: "90px" },
        { text: "Empresa", value: "empresa_id" },
        { text: "Tipo Guia", value: "tipo_guia" },
        { text: "Valor Guia", value: "valor_guia", align: "right" },
        { text: "Valor a Pagar", value: "valor_a_pagar", align: "right" },
        { text: "Competência", align: "center" },
        { text: "Data Comp.", value: "data_compensacao", align: "center" },
        { text: "Data Recebimento", value: "data_recebimento_boleto", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      guias: [],
      filtrando: false,
      impressoes: [
        { 
          text: 'Relatório Padrão', 
          value: 'imprimir_relatorio_padrao', 
          action: this.imprimir
        },
        { 
          text: 'Relatório Padrão com CNPJ', 
          value: 'imprimir_relatorio_cnpj', 
          action: this.imprimirCNPJ
        }
      ],
      urlImpressao: '',

    }
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapGetters('listas', ['empresas', 'parceiros']),

    anos() {
      return range(new Date().getFullYear(), 2020);
    },
  },

  mounted() {
    this.setTitulo('Relatório - Guias Emitidas');
    // this.filtro.data_inicial = this.$dayjs().startOf('month').format('YYYY-MM-DD');
    // this.filtro.data_final = hoje();

    this.$store.dispatch('listas/empresas');
    this.$store.dispatch('listas/parceiros');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),

    async filtrar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.filtrando = true;
        this.guias = await relatoriosApi.guiasLancadas(this.filtro);
      } catch (error) {
        this.$eventbus.notificarErro('', error);
        throw error;
      } finally {
        this.filtrando = false;
      }
    },

    onChangeDataInicial() {
      // this.filtro.data_final = this.filtro.data_inicial;
      this.limparGuias();
    },

    limparGuias() {
      this.guias = [];
    },

    imprimir() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      // abrirJanela(`${print_url}/relatorios/guiaslancadas/imprimir?` + new URLSearchParams(this.filtro).toString());

      setTimeout(() => {
        this.$refs.dialogoImpressao.abrir();
        this.urlImpressao = `${print_url}/relatorios/guiaslancadas/imprimir?` + new URLSearchParams(this.filtro).toString();
      }, 300);
    },

    imprimirCNPJ() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      // abrirJanela(`${print_url}/relatorios/guiaslancadas/imprimir?cnpj=1&` + new URLSearchParams(this.filtro).toString());

      setTimeout(() => {
        this.$refs.dialogoImpressao.abrir();
        this.urlImpressao = `${print_url}/relatorios/guiaslancadas/imprimir?cnpj=1&` + new URLSearchParams(this.filtro).toString();
      }, 300);
    },
  },

  watch: {
    filtro: {
      handler(newValue, oldValue) {
        this.filtrar();
      },
      deep: true
    }
  },
};
</script>

<style lang="scss" scoped>

  .label {
    font-size: 12px;
  }
</style>
