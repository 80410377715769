import contasReceberApi from "@/app/services/contasReceberApi";
import contareceber from "@/app/store/models/contareceber";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

import { defineStore } from 'pinia'

import { useGlobalStore } from '@/app/store/stores/global'

export const useContasReceberStore = defineStore('contasreceber', {
  state: () => ({
    contareceber: {...contareceber},
    contasreceber: [],
    busca: "",
    total_itens: 4,
    paginacao: PAGINATION_OPTIONS,
    loading: false
  }),

  getters: {},

  actions: {
    stores() {
      return {
        globalStore: useGlobalStore()
      }
    },
    
    async listar(filtro) {
      // const globalStore = useGlobalStore();

      this.stores().globalStore.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0],
        ...filtro
      }

      if (this.busca) {
        paginacao.busca = this.busca;
      }

      console.log(paginacao);

      let response = await contasReceberApi.listar(paginacao);
      this.contasreceber = response.data;
      this.total_itens = response.total;
      this.stores().globalStore.loading = false;
    },

    async abrir(id) {
      this.stores().globalStore.loading = true;
      this.contareceber = await contasReceberApi.abrir(id);
      this.stores().globalStore.loading = false;
    },

    async salvar(contareceber) {
      const novocontareceber = await contasReceberApi.salvar(contareceber);
      this.contasreceber.push(novocontareceber);
    },

    async atualizar(contareceber) {
      await contasReceberApi.atualizar(contareceber);
      const index = this.contasreceber.findIndex(contareceber_ => contareceber_.id == contareceber.id);
      if (index > -1) {
        this.contasreceber.splice(index, 1, contareceber);
      }
    },

    async excluir(id) {
      await contasReceberApi.excluir(id);
      const index = this.contasreceber.findIndex(contareceber => contareceber.id == id);
      if (index > -1) {
        this.contasreceber.splice(index, 1);
      }
    },

    async darBaixa(contareceber) {
      await contasReceberApi.darBaixa(contareceber);
      const contareceber_ = this.contasreceber.find(contareceber_ => contareceber_.id == contareceber.id);
      console.log('Conta a Receber:', contareceber_);
      contareceber_.data_recebimento_boleto = contareceber.data_recebimento_boleto;
      contareceber_.valor_a_pagar = contareceber.valor_a_pagar;
      contareceber_.recebido = 1;
    },

    async cancelarRecebimento(contareceber) {
      await contasReceberApi.cancelarRecebimento(contareceber);
      const contareceber_ = this.contasreceber.find(contareceber_ => contareceber_.id == contareceber.id);
      contareceber_.data_recebimento_boleto = null;
      contareceber_.valor_a_pagar = contareceber.valor_a_pagar;
      contareceber_.recebido = 0;
    },

    SET_BUSCA(busca) {
      this.busca = busca;
    },

    SET_PAGE(page) {
      this.paginacao.page = page;
    },

    SET_TOTAL_ITENS(total_itens) {
      this.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(paginacao) {
      this.paginacao = paginacao;
    },

    ZERAR() {
      this.contareceber = {...contareceber};
    }

  },

})