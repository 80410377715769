import contasPagarApi from "@/app/services/contasPagarApi";
import contapagar from "@/app/store/models/contapagar";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

import { defineStore } from 'pinia'

import { useGlobalStore } from '@/app/store/stores/global'

export const useContasPagarStore = defineStore('contaspagar', {
  state: () => ({
    contapagar: {...contapagar},
    contaspagar: [],
    busca: "",
    total_itens: 4,
    paginacao: PAGINATION_OPTIONS,
    loading: false
  }),

  getters: {},

  actions: {
    stores() {
      return {
        globalStore: useGlobalStore()
      }
    },
    
    async listar(filtro) {
      // const globalStore = useGlobalStore();

      this.stores().globalStore.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0],
        ...filtro
      }

      if (this.busca) {
        paginacao.busca = this.busca;
      }

      let response = await contasPagarApi.listar(paginacao);
      this.contaspagar = response.data;
      this.total_itens = response.total;
      this.stores().globalStore.loading = false;
    },

    async abrir(id) {
      this.stores().globalStore.loading = true;
      this.contapagar = await contasPagarApi.abrir(id);
      this.stores().globalStore.loading = false;
    },

    async salvar(contapagar) {
      const novocontapagar = await contasPagarApi.salvar(contapagar);
      this.contaspagar.push(novocontapagar);
    },

    async atualizar(contapagar) {
      await contasPagarApi.atualizar(contapagar);
      const index = this.contaspagar.findIndex(contapagar_ => contapagar_.id == contapagar.id);
      if (index > -1) {
        this.contaspagar.splice(index, 1, contapagar);
      }
    },

    async excluir(id) {
      await contasPagarApi.excluir(id);
      const index = this.contaspagar.findIndex(contapagar => contapagar.id == id);
      if (index > -1) {
        this.contaspagar.splice(index, 1);
      }
    },

    async pagar(contapagar, data_recebimento) {
      await contasPagarApi.pagar(contapagar, data_recebimento);
      const contapagar_ = this.contaspagar.find(contapagar_ => contapagar_.id == contapagar.id);
      contapagar_.data_transferencia = data_recebimento;
      contapagar_.pago = 1;
    },

    async cancelarPagamento(contapagar) {
      await contasPagarApi.cancelarPagamento(contapagar);
      const contapagar_ = this.contaspagar.find(contapagar_ => contapagar_.id == contapagar.id);
      contapagar_.data_transferencia = null;
      contapagar_.pago = 0;
    },

    SET_BUSCA(busca) {
      this.busca = busca;
    },

    SET_PAGE(page) {
      this.paginacao.page = page;
    },

    SET_TOTAL_ITENS(total_itens) {
      this.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(paginacao) {
      this.paginacao = paginacao;
    },

    ZERAR() {
      this.contapagar = {...contapagar};
    }

  },

})