<template>
  <v-app>
    <app-top-bar @navIconClick="drawer = !drawer"></app-top-bar>

    <app-drawer width="250" :drawer.sync="drawer"></app-drawer>

    <v-main class="grey lighten-4">
      <v-container v-if="false">
        <v-row>
          <v-col cols="12" align="center">
            <v-btn color="primary" @click="alertar" class="mr-3">Alertar</v-btn>
            <v-btn color="primary" @click="outroAlerta">outroAlerta</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-slide-y-transition mode='out-in'>
      <router-view></router-view>
      </v-slide-y-transition>
    </v-main>

    <v-snackbar bottom v-model="snackbar.exibir" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.mensagem }}
      <template #actions>
        <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Fechar</v-btn>
        <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Detalhes</v-btn>
      </template>
    </v-snackbar>

    <z-notificacao ref="notificacao"></z-notificacao>

    <z-alerta ref="alerta" persistent></z-alerta>

    <!-- <z-debug-info v-show="!$config.IS_PRODUCTION"></z-debug-info> -->

    <v-snackbar absolute bottom center vertical :value="updateExists" :timeout="-1" color="primary">
      Uma nova atualização está disponível.
      <template v-slot:action>
        <v-btn small color="primary" @click="refreshApp">
          Atualizar Agora
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import events from "@/app/mixins/events";
import config from "@/app/config/app";
import { mapState } from "vuex";

import MenuPrincipal from "./menus/MenuPrincipal";
import AppTopBar from "./AppTopBar";
import AppDrawer from "./AppDrawer";
import AppUserMenu from "./AppUserMenu";
import zDebugInfo from "@/components/zDebugInfo";

export default {
  name: "app-layout2",
  // mixins: [events],

  components: {
    MenuPrincipal,
    AppTopBar,
    AppDrawer,
    AppUserMenu,
    zDebugInfo,
  },

  data() {
    return {
      config: config,
      drawer: false,
      right: true,
      rightDrawer: false,
      title: "Tela Inicial",
      snackbar: {
        exibir: false,
        mensagem: "",
        timeout: 3000,
        color: "",
      },
      menu_cadastros: [{ texto: "Clientes" }, { texto: "Fornecedores" }, { texto: "Vendedores" }],
      updateExists: false,
    };
  },

  computed: {
    ...mapState({
      tituloTela: (state) => state.titulo_tela,
      loading: (state) => state.loading,
    }),
  },

  created() {
    this.$eventbus.$on("SOFTWARE_UPDATE", this.updateAvailable);
  },

  mounted() {
    this.$store.dispatch("carregarConfiguracoes");
    events.start(this);
  },

  beforeDestroy() {
    events.stop();
  },

  methods: {
    alertar() {
      this.$eventbus.alertar("Isso é um alerta");
    },

    outroAlerta() {
      this.$eventbus.notificar("UNAUTHORIZED");
    },

    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      // this.refreshApp();
    },

    refreshApp() {
      this.updateExists = false;
      // // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    },
  },
};
</script>
