<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'EmpresasLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      
      <v-btn
        v-show="empresa.id"
        color="primary"
        :disabled="salvando"
        @click="goTo('#parceiros')"
      >Parceiros</v-btn>

      <v-divider
        v-show="empresa.id"
        vertical
        class="mx-3"
      ></v-divider>

      <v-btn
        text
        :disabled="salvando"
        @click="$router.push({ name: 'EmpresasLista' })"
      >Cancelar</v-btn>
      <v-btn
        class="ml-3"
        color="primary"
        :loading="salvando"
        :disabled="!form_valido"
        @click="confirmar"
      >Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION" class="scrollLayout">

      <!-- <v-card class="fixed-card" style="max-width: 200px;">
        <v-list dense rounded>
          <v-subheader>Navegação</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" @click="goTo(item.id)">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card> -->

      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6" id="dados_empresa">
            <v-card-title class="text-subtitle-2">Dados do Empresa </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="10">
                  <z-text-field
                    upper
                    label="Razão"
                    v-model="empresa.razao"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
                
                <v-col cols="12" sm="2">
                  <z-date-picker-menu label="Cadastro" v-model="empresa.data_cadastro" tabindex="-1" readonly></z-date-picker-menu>
                </v-col>
                
                <v-col cols="12" sm="12">
                  <z-text-field
                    upper
                    label="Fantasia"
                    v-model="empresa.fantasia"
                  ></z-text-field>
                </v-col>
                
                <v-col cols="12" sm="3">
                  <z-cpf-cnpj label="CNPJ" v-model="empresa.cpf_cnpj" :rules="[regras.campoObrigatorio]"></z-cpf-cnpj>
                </v-col>
                
                <v-col cols="12" sm="3">
                  <z-select label="Regime Tributário" v-model="empresa.regime_tributario" :items="$globals.REGIMES_TRIBUTARIOS"></z-select>
                </v-col>

                <v-col cols="12" sm="2">
                  <z-campo-valor label="Percentual Desconto" v-model="empresa.percentual_desconto" :rules="[regras.maiorQueZero]" decimais="0" suffix="%"></z-campo-valor>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <v-card tile class="mb-6" id="enderecamento">
            <v-card-title class="text-subtitle-2">Endereçamento Empresa</v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-cep upper label="Cep" v-model="empresa.cep" buscarcep @resultado="preencherEndereco"></z-cep>
                </v-col>
                <v-col cols="12" sm="10">
                  <z-text-field upper label="Endereço" v-model="empresa.endereco"></z-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <z-text-field upper label="Bairro" v-model="empresa.bairro"></z-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <z-text-field upper label="Cidade" v-model="empresa.cidade"></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-select label="Estado" v-model="empresa.estado" :items="$globals.ESTADOS"></z-select>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field upper label="Complemento" v-model="empresa.complemento"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone" v-model="empresa.telefone_fixo" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="3">
                  <z-text-field label="Telefone Celular" v-model="empresa.telefone_celular" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field lower label="Email" v-model="empresa.email" :rules="[regras.emailValido]"></z-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>            
          </v-card>
          
          <v-card tile class="mb-6" id="responsavel">
            <v-card-title class="text-subtitle-2">Dados Responsável</v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="9">
                  <z-text-field upper label="Responsável" v-model="empresa.responsavel"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-cpf-cnpj label="CPF" v-model="empresa.responsavel_cpf"></z-cpf-cnpj>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-cep upper label="Cep" v-model="empresa.responsavel_cep" buscarcep @resultado="preencherEnderecoResponsavel"></z-cep>
                </v-col>
                <v-col cols="12" sm="10">
                  <z-text-field upper label="Endereço" v-model="empresa.responsavel_endereco"></z-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <z-text-field upper label="Bairro" v-model="empresa.responsavel_bairro"></z-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <z-text-field upper label="Cidade" v-model="empresa.responsavel_cidade"></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-select label="Estado" v-model="empresa.responsavel_estado" :items="$globals.ESTADOS"></z-select>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field upper label="Complemento" v-model="empresa.responsavel_complemento"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone" v-model="empresa.responsavel_telefone" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

        <EmpresasParceiros id="parceiros" v-if="empresa.id" :empresa="empresa"></EmpresasParceiros>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";

import * as regras from "@/app/helpers/validacoes";

import EmpresasParceiros from "./EmpresasParceiros.vue";

export default {
  name: "EmpresasForm",

  components: {
    EmpresasParceiros,
  },

  data() {
    return {
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },
      selectedItem: 1,
      items: [
        { text: 'Dados da Empresa', id: '#dados_empresa' },
        { text: 'Endereçamento Empresa', id: '#enderecamento' },
        { text: 'Dados Responsável', id: '#responsavel' },
        { text: 'Parceiros', id: '#parceiros' },
      ],

      error: {
        message: "",
      },
    };
  },

  computed: {
    // ...mapGetters("listas", ["grupos", "subgrupos"]),
    ...mapState('empresas', ['empresa']),


    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Empresa";
    },
  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "EmpresasEditar") {
      if (!this.$store.state.empresas.empresa.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
        
      // this.empresa = clone(this.$store.state.empresas.empresa);
    }
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("empresas", ["ZERAR"]),
    ...mapActions("empresas", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.empresa);
        } else {
          await this.salvar(this.empresa);
        }

        this.$router.push({ name: "EmpresasLista" });
        this.$eventbus.notificar("Empresa salva com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar este empresa. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    preencherEndereco(endereco) {
      if (endereco.erro) {
        this.empresa.endereco = "";
        this.empresa.bairro = "";
        this.empresa.cidade = "";
        this.empresa.estado = "";
      } else {
        this.empresa.endereco = endereco.logradouro.toUpperCase();
        this.empresa.bairro = endereco.bairro.toUpperCase();
        this.empresa.cidade = endereco.localidade.toUpperCase();
        this.empresa.estado = endereco.uf.toUpperCase();
        // this.$refs.telefone1.focus();
      }
    },

    preencherEnderecoResponsavel(endereco) {
      if (endereco.erro) {
        this.empresa.responsavel_endereco = "";
        this.empresa.responsavel_bairro = "";
        this.empresa.responsavel_cidade = "";
        this.empresa.responsavel_estado = "";
      } else {
        this.empresa.responsavel_endereco = endereco.logradouro.toUpperCase();
        this.empresa.responsavel_bairro = endereco.bairro.toUpperCase();
        this.empresa.responsavel_cidade = endereco.localidade.toUpperCase();
        this.empresa.responsavel_estado = endereco.uf.toUpperCase();
        // this.$refs.telefone1.focus();
      }
    },

    goTo(id) {
      this.$vuetify.goTo(id, { container: ".scrollLayout" });
    }
  },
};
</script>

<style scoped>

.fixed-card {
  position: fixed;
  top: calc(128px + 50px);
  left: 64px;
  z-index: 1000;
}
</style>
