<template>
  <z-dialogo ref="dialogo" titulo="Compensação" largura="900px" persistent dividers>
    <template v-slot:conteudo>
      <v-form v-model="form_valido" ref="form" lazy-validation>
        <v-row dense>
          <v-col cols="12" sm="4">
            <z-text-field 
              v-model="guia.codigo_deposito_judicial"
              :hint="dataCompesacao"
              persistent-hint
              ref="codigojudicial" 
              select 
              label="Código Depósio Judicial" 
              :rules="[regras.campoObrigatorio]"
            ></z-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <z-date-picker-menu 
              v-model="guia.vencimento_boleto"
              label="Vencimento Boleto"
              :rules="[regras.campoObrigatorio]"
            ></z-date-picker-menu>
          </v-col>

          <v-col cols="12" sm="4">
            <z-campo-valor 
              v-model="guia.valor_a_pagar" 
              label="Valor a Pagar" 
              decimais="2" 
              prefix="R$" 
              readonly
            ></z-campo-valor>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:actions_left>
      <v-chip v-if="guia.data_recebimento_boleto" color="green" dark small>Pago</v-chip>
    </template>
    <template v-slot:actions_right>

      <template v-if="!guia.data_recebimento_boleto && guia.data_compensacao">
        <z-delete
          texto-botao="Cancelar compensação"
          cor-botao="red"
          titulo="Cancelar compensação"
          botaoSim="Sim"
          botaoNao="Não"
          cor-botao-sim="red"
          @click:sim="confirmarCancelamento(guia)"
          :loading="cancelando"
        >
          Esta compensação será cancelada bem como a conta a pagar lançada. Deseja cancelar?
        </z-delete>

        <v-divider vertical inset class="mx-3 mb-2"></v-divider>
      </template>

      <v-btn text @click="fechar" :disabled="salvando">{{ textoBtnCancelar }}</v-btn>
      <v-btn v-if="!statusGuia.isPaga && !statusGuia.isCompensada" text color="primary" @click="compensarGuia" :loading="salvando" :disabled="!form_valido">Compensar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import * as regras from "@/app/helpers/validacoes";
import { dataBR } from "@/app/helpers/formatacoes";

export default {
  name: "DialogoCompensacao",

  data() {
    return {
      salvando: false,
      form_valido: true,
      regras: { ...regras },
      cancelando: false
    };
  },

  computed: {
    ...mapState('guias', ['guia']),

    textoBtnCancelar() {
      return this.guia.data_recebimento_boleto ? 'Fechar' : 'Fechar';
    },

    dataCompesacao() {
      return this.guia.data_compensacao ? `Data Compensação: ${dataBR(this.guia.data_compensacao)}` : "";
    },

    statusGuia() {
      return {
        isPaga: !!this.guia.data_recebimento_boleto,
        isCompensada: !!this.guia.data_compensacao,
      }
    }
  },

  methods: {
    ...mapMutations('guias', ['ZERAR']),
    ...mapActions('guias', ['abrir', 'compensar', 'salvar', 'cancelarCompensacao']),

    abrir() {
      this.$refs.dialogo.abrir();
      this.$emit('abrir');
      setTimeout(() => {
        this.$refs.form.resetValidation();
      }, 0);
    },

    fechar() {
      this.ZERAR();
      this.$refs.dialogo.fechar();
      this.$emit('fechar');
    },

    async compensarGuia() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      try {
        this.salvando = true;
        await this.compensar(this.guia);
        this.$eventbus.notificar('Compensação efetuada sucesso.');
        this.fechar();
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível efetuar a compensação desta guia. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    async confirmarCancelamento(guia) {
      try {
        this.cancelando = true;
        await this.cancelarCompensacao(guia);
        this.$eventbus.notificar('Compensação cancelar sucesso.');
        this.fechar();
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível cancelar a compensação desta guia. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.cancelando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
