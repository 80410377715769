import createCrudApi from "@/app/services/baseApi";
const empresasApi = createCrudApi('empresas');

const { axios } = empresasApi;

empresasApi.salvarParceiro = function(parceiro) {
  return axios.post('empresas/parceiros', parceiro).then((response) => response.data);
}

empresasApi.atualizarParceiro = function(parceiro) {
  return axios.put('empresas/parceiros', parceiro).then((response) => response.data);
}

empresasApi.removerParceiro = function(parceiro_id) {
  return axios.delete(`empresas/parceiros`, { params: {id: parceiro_id} }).then((response) => response.data);
}

export default empresasApi;
