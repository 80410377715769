<template>
  <div>
    <z-centralized-toolbar fluid>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text color="primary" @click="adicionar">
          <v-icon left>{{ $icon.ADICIONAR }}</v-icon>Adicionar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          @click="refresh"
          :loading="$store.state.loading"
        >
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="parceiros"
            item-key="id"
          >
            <template v-slot:item="{ item: parceiro }">
              <tr>
                <td>{{ parceiro.id }}</td>
                <td>
                  <a class="text-decoration-none" @click.prevent="editar(parceiro)">
                    {{ parceiro.nome }}
                  </a>
                </td>
                <td class="text-right">{{ parceiro.telefone_celular }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(parceiro)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(parceiro)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemover"
      titulo="Remover parceiro"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      A parceiro será removida do sistema. Deseja remover?
    </z-dialogo-sim-nao>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "ParceirosLista",

  data() {
    return {
      titulo_tela: 'Parceiros',
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Nome", value: "nome" },
        { text: "Telefone", value: "telefone_celular", align: "right" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      parceiro_codigo: "",
    };
  },

  computed: {
    ...mapState(["loading"]),
    ...mapState("parceiros", ["parceiros", "parceiro", "total_itens"]),

    busca: {
      get() {
        return this.$store.state.parceiros.busca;
      },
      set(busca) {
        this.$store.commit("parceiros/SET_BUSCA", busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.parceiros.paginacao;
      },
      set(paginacao) {
        this.$store.commit("parceiros/UPDATE_PAGINACAO", paginacao);
      },
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo(this.titulo_tela);
  },

  methods: {
    ...mapMutations(["setTitulo", "LOADING_OFF"]),
    ...mapActions("parceiros", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível listar os parceiros.", error);
      }
    },

    buscar() {
      this.$store.commit("parceiros/SET_PAGE", 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit("parceiros/SET_PAGE", 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit("parceiros/ZERAR");
      this.$router.push({ name: "ParceirosAdicionar" });
    },

    async editar(parceiro) {
      try {
        await this.abrir(parceiro.id);
        this.$router.push({ name: "ParceirosEditar", params: { id: parceiro.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a parceiro.", error);
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(parceiro) {
      this.$refs.dialogoRemover.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(parceiro);
          this.$refs.dialogoRemover.fechar();
        }
      });
    },

    async confirmarExcluir(parceiro) {
      try {
        this.excluindo = true;
        await this.excluir(parceiro.id);
        this.$eventbus.notificar("Parceiro excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível excluir o parceiro!", error);
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
