import guiasApi from "@/app/services/guiasApi";
import guia from "@/app/store/models/guia";
import { PAGINATION_OPTIONS } from "@/app/vuetify.config";

import { defineStore } from 'pinia'

import { useGlobalStore } from '@/app/store/stores/global'

export const useGuiasStore = defineStore('guias', {
  state: () => ({
    guia: {...guia},
    guias: [],
    busca: "",
    total_itens: 4,
    paginacao: PAGINATION_OPTIONS,
    loading: false,
  }),

  getters: {},

  actions: {
    async listar(filtro) {
      const globalStore = useGlobalStore();
      
      globalStore.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0],
        ...filtro
      }

      if (this.busca) {
        paginacao.busca = this.busca;
      }

      let response = await guiasApi.listar(paginacao);
      this.guias = response.data;
      this.total_itens = response.total;
      globalStore.loading = false;
    },

    async abrir(id) {
      this.loading = true;
      this.guia = await guiasApi.abrir(id);
      this.loading = false;
    },

    async salvar(guia) {
      const novoguia = await guiasApi.salvar(guia);
      this.guias.push(novoguia);
    },

    async atualizar(guia) {
      await guiasApi.atualizar(guia);
      const index = this.guias.findIndex(guia_ => guia_.id == guia.id);
      if (index > -1) {
        this.guias.splice(index, 1, guia);
      }
    },

    async excluir(id) {
      await guiasApi.excluir(id);
      const index = this.guias.findIndex(guia => guia.id == id);
      if (index > -1) {
        this.guias.splice(index, 1);
      }
    },

    SET_BUSCA(busca) {
      this.busca = busca;
    },

    SET_PAGE(page) {
      this.paginacao.page = page;
    },

    SET_TOTAL_ITENS(total_itens) {
      this.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(paginacao) {
      this.paginacao = paginacao;
    },

    ZERAR() {
      this.guia = {...guia};
    }

  },

})