import ParceirosLista from '@/pages/cadastros/parceiros/ParceirosLista'
import ParceirosForm from '@/pages/cadastros/parceiros/ParceirosForm'

export default [
  {
    path: '/cadastros/parceiros', 
    name: 'ParceirosLista', 
    component: ParceirosLista,
    meta: {layout: 'principal', permissao: 'parceiros_acessar'}
  },
  
  {
    path: '/cadastros/parceiros/adicionar', 
    name: 'ParceirosAdicionar', 
    component: ParceirosForm,
    meta: {layout: 'principal', permissao: 'parceiros_adicionar'}
  },
  
  {
    path: '/cadastros/parceiros/:id/editar', 
    name: 'ParceirosEditar', 
    component: ParceirosForm,
    meta: { layout: 'principal', permissao: 'parceiros_editar'}
  }
];