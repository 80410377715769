<template>
  <v-select ref="input" v-bind="attributes" v-on="$listeners"></v-select>
</template>

<script>
import form_mixin from "./form_mixin";

export default {
  name: "z-select",
  
  mixins: [form_mixin],

  props: {
    configOff: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    attributes() {
      if (this.configOff) {
        return { ...this.$attrs };
      }
      return { ...this.$v.TEXT_FIELDS, ...this.$attrs };
    },
  },
}
</script>

<style lang="scss" scoped>
</style>