<template>
  <v-form ref="form" v-model="valido" lazy-validation>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title primary-title>
          <div class="title">Enviar Arquivo - {{ guia.id }}</div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-3">

          <v-row>
            <v-col sm="12">
              <z-text-field
                v-model="arquivo.descricao"
                ref="descricao"
                label="Descrição Arquivo"
                autofocus
                required
                :rules="rules"
              ></z-text-field>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col sm="5">
              <v-select
                :items="meses"
                label="Mês de Referência"
                v-model="arquivo.mes"
              ></v-select>
            </v-col>
            <v-col sm="4">
              <v-select :items="anos" label="Ano" v-model="arquivo.ano"></v-select>
            </v-col>
          </v-row> -->

          <input
            type="file"
            ref="fileinput"
            style="display: none"
            accept="application/pdf,application/vnd.ms-excel"
            @change="onChangeFile"
          />

          <v-row v-if="!arquivo.nome" justify-center align-center>
            <v-col>
              <div class="dropzone" @click="escolherArquivo">
                <span>Clique aqui para adicionar um arquivo</span>
              </div>
            </v-col>
          </v-row>

          <v-row v-else align-center>
            <v-col cols="12">
              <div class="dropzone-uploading">
                <span>{{ arquivo.nome }}</span>
              </div>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                v-model="progress"
                height="5"
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="loading" @click="fechar">Cancelar</v-btn>
          <v-btn
            text
            color="primary"
            :disabled="loading || !this.selectedFile"
            @click.native="upload"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from "axios";

export default {

  props: {
    guia: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      tela: "Arquivos",
      selectedFile: null,
      progress: 0,
      loading: false,
      dialog: false,
      arquivo: {
        nome: '',
        descricao: '',
        tipoarquivo: '',
        mes: null,
        ano: null,
      },
      tiposligacoes: [
        { text: "Detalhado", value: "detalhado" },
        { text: "Natureza Ligações", value: "natureza" },
      ],
      valido: false,
      rules: [],
      meses: [
        { text: "Janeiro", value: 1 },
        { text: "Fevereiro", value: 2 },
        { text: "Março", value: 3 },
        { text: "Abril", value: 4 },
        { text: "Maio", value: 5 },
        { text: "Junho", value: 6 },
        { text: "Julho", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Setembro", value: 9 },
        { text: "Outubro", value: 10 },
        { text: "Novembro", value: 11 },
        { text: "Dezembro", value: 12 },
      ],
      anos: [],
    };
  },

  mounted() {
    this.anos = this.gerarAnos();
    this.arquivo.mes = this.meses[new Date().getMonth() - 1].value;
    this.arquivo.ano = new Date().getFullYear();
  },

  methods: {
    gerarAnos() {
      let inicio = 2018;
      let fim = new Date().getFullYear();
      let diferenca = fim - inicio;
      let anos = [];

      if (diferenca <= 5) {
        diferenca = 5;
      }

      for (let index = 0; index <= diferenca; index++) {
        anos.push(inicio + index);
      }

      return anos;
    },

    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.selectedFile = null;
      this.arquivo.nome = '';
      this.arquivo.descricao = '';
      this.arquivo.tipo = '';
      this.rules = [];
      this.$refs.fileinput.value = null;
      this.$emit("cancelar");
    },

    onChangeFile(e) {
      this.arquivo.nome = e.target.files[0].name;
      this.selectedFile = e.target.files[0];
    },

    upload() {
      this.carregarRegras();
      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          if (!this.selectedFile) {
            return false;
          }

          this.loading = true;
          this.progress = 0;
          const fd = new FormData();
          fd.append("arquivo", this.selectedFile);
          fd.append("descricao", this.arquivo.descricao);
          // fd.append("mes", this.arquivo.mes);
          // fd.append("ano", this.arquivo.ano);
          // fd.append("tipoarquivo", this.arquivo.tipoarquivo);

          this.$axios
            .post("arquivos/" + this.guia.id, fd, {
              onUploadProgress: (uploadEvent) => {
                this.progress = Math.round(
                  (uploadEvent.loaded / uploadEvent.total) * 100
                );
              },
              headers: {
                "content-type": "multipart/form-data",
              },
            })
            .then((response) => {
              this.loading = false;
              this.progress = 0;
              this.fechar();
              this.$emit("onConcluir", response.data);
            })
            .catch((error) => {
              this.loading = false;
              this.progress = 0;
              console.log(error);
            });
        } else {
          this.$refs.descricao.focus();
        }
      });
    },

    escolherArquivo() {
      this.$refs.fileinput.click();
    },

    carregarRegras() {
      this.rules = [
        (v) => (!!v && v.length >= 5) || "Informe uma descrição para esse arquivo",
      ];
    },

    uploadTimer() {
      this.progress = 0;
      var interval = setInterval(() => {
        this.progress = this.progress + 10;
        if (this.progress == 100) {
          clearInterval(interval);
        }
        console.log(this.progress);
      }, 1000);
    },
  },

  computed: {},
};
</script>

<style>
.dropzone {
  border: 2px dashed #bdbdbd;
  border-radius: 5px;
  background: #eeeeee;
  padding: 25px 25px;
  cursor: pointer;
  /* min-height: 100px; */
}

.dropzone-uploading {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  background: #eeeeee;
  padding: 15px 25px;
  /* min-height: 100px; */
}
</style>
