<template>
  <div>
    <z-centralized-toolbar fluid>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'GuiasLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-chip class="mr-4" v-if="guia.data_recebimento_boleto" color="green" dark small>Pago</v-chip>
      
      <v-btn
        v-if="guia.id"
        text
        @click="$refs.arquivos.abrir()"
      ><v-icon left>{{ $icon.ANEXOS }}</v-icon> Arquivos</v-btn>

      <v-divider v-if="guia.id" vertical inset class="mx-3"></v-divider>


      <v-btn
        text
        :disabled="salvando"
        @click="$router.push({ name: 'GuiasLista' })"
      >
        Cancelar
      </v-btn>
      <v-btn
        class="ml-3"
        color="primary"
        :loading="salvando"
        :disabled="!form_valido || guia.data_recebimento_boleto"
        @click="confirmar"
      >Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">Dados do Guia<span v-if="guia.id">&nbsp;- {{ guia.id }}</span></v-card-title>

            <v-divider></v-divider>

            <v-card-text>

              <v-row dense>
                <v-col cols="12" sm="6">
                  <z-select
                    v-model="guia.empresa_id"
                    label="Empresa"
                    return-object_
                    item-value="id"
                    item-text="descricao"
                    :readonly="$config.IS_PRODUCTION"
                    :items="empresas"
                    :rules="[regras.campoObrigatorio]"
                    @change="onChangeEmpresa"
                  ></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Regime Tributário" :value="regimeTributario" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor label="Desconto" v-model="guia.percentual_desconto" decimais="2" suffix="%" readonly></z-campo-valor>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-select
                    v-model="guia.competencia_mes"
                    label="Mês Competência"
                    :items="$globals.MESES"
                  ></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-select
                    v-model="guia.competencia_ano"
                    label="Ano Competência"
                    :items="anos"
                  ></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-date-picker-menu left label="Recebimento Guia" v-model="guia.data_recebimento_guia"></z-date-picker-menu>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-select
                    v-model="guia.tipo_guia"
                    label="Tipo de Guia"
                    :items="tiposDeGuias"
                    :rules="[regras.campoObrigatorio]"
                  ></z-select>
                </v-col>
              </v-row>

              <v-row dense>

                <v-col cols="12" sm="3">
                  <z-campo-valor label="Valor da Guia" v-model="guia.valor_guia" decimais="2" prefix="R$"></z-campo-valor>
                </v-col>

                <template v-if="isEmpresaSimples">

                  <v-col cols="12" sm="3" v-if="isGuiaDARFINSS">
                    <z-campo-valor label="Dedução Terceiros" v-model="guia.deducao_terceiros" decimais="2" prefix="R$"></z-campo-valor>
                  </v-col>
                  <v-col cols="12" sm="3" v-else>
                    <z-campo-valor label="Valor ISS/ICMS" v-model="guia.valor_iss_icms" decimais="2" prefix="R$"></z-campo-valor>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <z-campo-valor label="Valor a Compensar" v-model="guia.valor_a_compensar" decimais="2" prefix="R$" readonly></z-campo-valor>
                  </v-col>

                </template>

                <template v-if="!isEmpresaSimples && isGuiaDARFINSS">
                  
                  <v-col cols="12" sm="3">
                    <z-campo-valor label="Dedução Terceiros" v-model="guia.deducao_terceiros" decimais="2" prefix="R$"></z-campo-valor>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-campo-valor label="Valor a Compensar" v-model="guia.valor_a_compensar" decimais="2" prefix="R$" readonly></z-campo-valor>
                  </v-col>
                  
                </template>

                <v-col cols="12" sm="3">
                  <z-campo-valor label="Dedução" v-model="guia.deducao" decimais="2" prefix="R$" readonly></z-campo-valor>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-campo-valor label="Valor a Pagar" v-model="guia.valor_a_pagar" decimais="2" prefix="R$" readonly></z-campo-valor>
                </v-col>
                
                <v-col cols="12" sm="3">
                  <z-date-picker-menu left label="Vencimento" v-model="guia.vencimento_boleto"></z-date-picker-menu>
                </v-col>

              </v-row>

            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>

    <arquivos ref="arquivos" :guia="guia"></arquivos>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";

import * as regras from "@/app/helpers/validacoes";
import empresas from "../../../app/router/routes/empresas";
import { range } from "@/app/helpers/utils"

import Arquivos from './Arquivos'


export default {
  name: "GuiasForm",

  components: {
    Arquivos
  },

  data() {
    return {
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },

      regime_tributario: '',
    };
  },

  computed: {
    ...mapGetters("listas", ["empresas", "parceiros"]),
    ...mapState('guias', ['guia', 'filtro']),


    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";

      if (this.estaVisualizando) {
        titulo = "Exibindo"
      }
      return `${titulo} Guia`;
    },

    regimeTributario() {
      if (this.guia.empresa_id) {
        let empresa = this.empresas.find(empresa => empresa.id == this.guia.empresa_id);
        const regime_tributario = !!empresa ? empresa.regime_tributario : '';
        return regime_tributario;
      }

      return '';
    },

    isEmpresaSimples() {
      return this.regimeTributario == 'Simples';
    },

    isGuiaSimplesNacional() {
      return this.guia.tipo_guia == "SIMPLES";
    },

    isGuiaDARFINSS() {
      return this.guia.tipo_guia == "DARF_INSS";
    },

    isOutrosTipoGuia() {
      return ["SIMPLES","DARF_INSS"].indexOf(this.guia.tipo_guia) == -1;
    },

    tiposDeGuias() {
      return this.$globals.TIPOS_GUIAS.filter(tipo => {
        if (this.regimeTributario == 'Simples') {
          return ['SIMPLES', 'DARF_INSS'].indexOf(tipo.value) > -1;
        } else {
          return ['DARF_INSS', 'DARF_PIS', 'DARF_COFINS', 'DARF_CSLL', 'DARF_IRPJ'].indexOf(tipo.value) > -1;
        }
      })
    },

    anos() {
      return range((new Date()).getFullYear(), 2020);
    },

    estaVisualizando() {
      return this.guia.data_recebimento_boleto;
    }

  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "GuiasEditar") {
      if (!this.$store.state.guias.guia.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
        
    } else {
      this.guia.empresa_id = this.filtro.empresa_id;
      this.onChangeEmpresa(this.filtro.empresa_id);
    }

    this.$store.dispatch('listas/empresas');
    this.$store.dispatch('listas/parceiros');
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("guias", ["ZERAR"]),
    ...mapActions("guias", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.guia);
        } else {
          await this.salvar(this.guia);
        }

        this.$router.push({ name: "GuiasLista" });
        this.$eventbus.notificar("Guia salva com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar este guia. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    onChangeEmpresa(empresa_id) {
      let empresa = this.empresas.find(empresa => empresa.id == empresa_id);
      if (empresa) {
        this.guia.empresa_id = empresa.id;
        this.guia.percentual_desconto = empresa.percentual_desconto;
  
        if (this.isEmpresaSimples) {
          this.guia.tipo_guia = 'SIMPLES';
        } else {
          this.guia.tipo_guia = 'DARF_PIS';
        }
      }
    },

    calcular() {
      this.calcularValorACompensar();
      this.calcularDeducao();
      this.calcularValorAPagar();
      // this.calcularValorTransferencia();
    },

    calcularValorACompensar() {
      if (this.isGuiaSimplesNacional) {
        this.guia.valor_a_compensar = this.guia.valor_guia - this.guia.valor_iss_icms;
        this.guia.deducao_terceiros = 0;
      } else {
        this.guia.valor_a_compensar = this.guia.valor_guia - this.guia.deducao_terceiros;
        this.guia.valor_iss_icms = 0;
      }
    },

    calcularDeducao() {
      if (this.isOutrosTipoGuia) {
        this.guia.deducao = this.guia.valor_guia * (this.guia.percentual_desconto / 100);
      } else {
        this.guia.deducao = this.guia.valor_a_compensar * (this.guia.percentual_desconto / 100);
      }
    },

    calcularValorAPagar() {
      if (this.isGuiaSimplesNacional || this.isGuiaDARFINSS) {
        this.guia.valor_a_pagar = this.guia.valor_a_compensar - this.guia.deducao;
      } else {
        this.guia.valor_a_pagar = this.guia.valor_guia - this.guia.deducao;
      }
    },

    calcularValorTransferencia() {
      if (this.isGuiaSimplesNacional) {
        this.guia.valor_transferencia = this.guia.valor_a_compensar * 0.15;
      } else {
        this.guia.valor_transferencia = this.guia.valor_guia * 0.15;
      }
    },


  },

  watch: {
    guia: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.calcular();
      }
    }
  },
};
</script>

<style scoped>
</style>
