<template>
  <div>

    <DrawerFiltroPagamentos v-model="filtroChips" ref="filtroContasPagar" @input="filtrar"></DrawerFiltroPagamentos>

    <z-centralized-toolbar cols="10" fluid>
      <v-toolbar-items>
        <v-btn text small color="primary" @click="abrirFiltro">
          <v-icon left>{{ $icon.FILTRAR }}</v-icon>Filtrar
        </v-btn>

        <v-btn
          text
          id="btn-atualizar"
          :loading="$store.state.loading"
          @click="refresh"
        >
          <v-icon>{{ $icon.REFRESH }}</v-icon>
          <v-tooltip bottom activator="#btn-atualizar"> Atualizar </v-tooltip>
        </v-btn>


      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
      
      <v-spacer></v-spacer>

      <!-- <v-toolbar-items>
        <v-btn text small color="primary" @click="imprimir">
          <v-icon left>{{ $icon.IMPRIMIR }}</v-icon>Imprimir
        </v-btn>
        <z-menu-imprimir @abrir:relatorio="abrirRelatorio" :itens="impressoes"></z-menu-imprimir>
      </v-toolbar-items> -->

    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container cols="10">
        <z-cabecalho>{{ globalStore.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-card-text v-if="filtroChips.length > 0">
            <div class="d-flex" style="gap: 10px; flex-wrap: wrap;">
              <v-chip
                v-for="(chip, index) in filtroChips"
                :key="index"
                close
                @click:close="$refs.filtroContasPagar.removerFiltro(chip)"
              >
                {{ chip.descricao }}
              </v-chip>
            </div>
          </v-card-text>
          <v-divider v-if="filtroChips.length > 0"></v-divider>

          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="contaspagar"
            item-key="id"
          >
            <template v-slot:item="{ item: contapagar }">
              <tr>
                <td>{{ contapagar.guia_id }}</td>
                <td>{{ contapagar.guia.empresa.razao }}</td>
                <td>{{ contapagar.parceiro.nome }}</td>
                <td class="text-center">{{ contapagar.data_pagamento | dataBR }}</td>
                <td class="text-center">{{ contapagar.guia.competencia }}</td>
                <td class="text-right text-weight-bold">{{ contapagar.valor_transferencia | valorBR }}</td>
                <td class="text-center">
                  <template v-if="contapagar.data_transferencia">
                    {{ contapagar.data_transferencia | dataBR }}
                  </template>
                  <v-chip v-else color="red" dark small>Em Aberto</v-chip>
                </td>
                <td class="text-center">
                  <v-btn
                    small
                    depressed
                    color="primary"
                    @click="pagar(contapagar)"
                  >
                    {{ !contapagar.pago ? 'Pagar' : 'Ver' }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <DialogoDarBaixaContasPagar ref="dialogoDarBaixa"></DialogoDarBaixaContasPagar>
  </div>
</template>

<script>
// import { mapMutations, mapActions, mapState } from "vuex";
import { mapActions, mapStores, mapWritableState } from 'pinia';
import { useContasPagarStore, useGlobalStore } from '@/app/store/stores';

import DialogoDarBaixaContasPagar from "./DialogoDarBaixaContasPagar";
import DrawerFiltroPagamentos from "./DrawerFiltroPagamentos";

export default {
  name: "ContasPagarLista",

  components: {
    DialogoDarBaixaContasPagar,
    DrawerFiltroPagamentos
  },

  data() {
    return {
      titulo_tela: 'Contas a Pagar - Parceiros',
      headers: [
        { text: "Guia", value: "guia_id", width: "100px" },
        { text: "Empresa", value: "guia.empresa.razao" },
        { text: "Parceiro", value: "parceiro.nome" },
        { text: "Data Recebimento", value: "data_pagamento", align: "center" },
        { text: "Competência", align: "center" },
        { text: "Valor a pagar ", value: "valor_transferencia", align: "right" },
        { text: "Data Transferência", value: "data_transferencia", align: "center" },
        {
          text: "Opções",
          value: "",
          width: "200px",
          align: "center",
          sortable: false,
        },
      ],
      excluindo: false,
      empresa_codigo: "",
      impressoes: [
        { texto: 'Caixa do Dia', acao: 'imprimir_caixa_dia', visible: true },
        { texto: 'Caixa Mensal', acao: 'imprimir_caixa_mensal', visible: true },
      ],
      filtroChips: [],
      filtro: {}
    };
  },

  computed: {
    ...mapStores(useContasPagarStore, useGlobalStore),
    ...mapWritableState(useContasPagarStore, ["contaspagar", "contapagar", "total_itens", 'paginacao', 'busca']),
    ...mapWritableState(useGlobalStore, ["loading"]),
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo(this.titulo_tela);
  },

  methods: {
    ...mapActions(useContasPagarStore, ["listar", "excluir", "abrir"]),
    ...mapActions(useGlobalStore, ["setTitulo", "LOADING_OFF"]),

    async refresh() {
      try {
        // this.filtro = {}

        await this.listar(this.filtro);
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar as contas a pagar.");
      }
    },

    buscar() {
      this.contaspagarStore.SET_PAGE(1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.contaspagarStore.SET_PAGE(1);
      this.refresh();
    },

    adicionar() {
      this.contaspagarStore.ZERAR();
      this.$router.push({ name: "EmpresasAdicionar" });
    },

    perguntarExcluir(empresa) {
      this.$refs.dialogoRemover.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(empresa);
          this.$refs.dialogoRemover.fechar();
        }
      });
    },

    async confirmarExcluir(empresa) {
      try {
        this.excluindo = true;
        await this.excluir(empresa.id);
        this.$eventbus.notificar("Empresa excluída com sucesso!");
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível excluir o empresa!", error);
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    async pagar(contapagar) {
      try {
        await this.abrir(contapagar.id);
        this.$refs.dialogoDarBaixa.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a conta a receber.", error);
      }
    },

    abrirRelatorio() {

    },

    abrirFiltro() {
      this.$refs.filtroContasPagar.abrir();
    },

    async filtrar() {
      try {
        this.filtro = {}

        this.filtroChips.forEach(item => {
          this.filtro[item.chave] = item.valor;
        });

        this.filtro.page = 1;
        this.paginacao.page = 1;

        await this.listar(this.filtro);
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar as contas a pagar.");
      }
    },
    
    imprimir() {
      
    }
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
