<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'ParceirosLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        text
        :disabled="salvando"
        @click="$router.push({ name: 'ParceirosLista' })"
        >Cancelar</v-btn
      >
      <v-btn
        class="ml-3"
        color="primary"
        :loading="salvando"
        :disabled="!form_valido"
        @click="confirmar"
        >Salvar</v-btn
      >
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">Dados do Parceiro </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="7">
                  <z-text-field
                    upper
                    label="Nome"
                    v-model="parceiro.nome"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
                
                <v-col cols="12" sm="3">
                  <z-cpf-cnpj label="CPF/CNPJ" v-model="parceiro.cpf_cnpj"></z-cpf-cnpj>
                </v-col>

                <v-col cols="12" sm="2">
                  <z-campo-valor label="Percentual Comissão" v-model="parceiro.percentual_desconto" decimais="0" suffix="%"></z-campo-valor>
                </v-col>
              </v-row>

              <v-row dense>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone Fixo" v-model="parceiro.telefone_fixo" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field label="Telefone Celular" v-model="parceiro.telefone_celular" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <z-text-field caps="lower" label="Email" v-model="parceiro.email" :rules="[regras.emailValido]"></z-text-field>
                </v-col>
                <v-col cols="12" sm="10">
                  <z-text-field
                    upper
                    label="Contato"
                    v-model="parceiro.contato"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-date-picker-menu label="Cadastro" v-model="parceiro.data_cadastro" tabindex="-1" readonly></z-date-picker-menu>
                </v-col>
              </v-row>

              <v-row dense>
                
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";
import parceiro from "@/app/store/models/parceiro";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "ParceirosForm",

  data() {
    return {
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },
    };
  },

  computed: {
    // ...mapGetters("listas", ["grupos", "subgrupos"]),
    ...mapState('parceiros', ['parceiro']),


    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Parceiro";
    },
  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "ParceirosEditar") {
      if (!this.$store.state.parceiros.parceiro.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
        
      // this.parceiro = clone(this.$store.state.parceiros.parceiro);
    }
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("parceiros", ["ZERAR"]),
    ...mapActions("parceiros", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.parceiro);
        } else {
          await this.salvar(this.parceiro);
        }

        this.$router.push({ name: "ParceirosLista" });
        this.$eventbus.notificar("Parceiro salva com sucesso");
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.codigo[0];
          return;
        }

        this.$eventbus.notificarErro("Não foi possível salvar este parceiro. Verifique os dados preenchidos.", error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },
  },
};
</script>

<style scoped>
</style>
