<template>
  <v-form ref="form" v-model="form_valido" lazy-validation>
    <z-dialogo ref="dialogo" titulo="Dados do Usuário" :dividers="true" largura="600px" @fechar="$refs.form.resetValidation()">

        <template v-slot:conteudo>

            <v-row dense>
              <v-col cols="12">
                <z-text-field placeholder=" " :rules="regras.nome" label="Nome" v-model="usuario.name" :disabled="isAdmin" autocomplete="off" autofocus></z-text-field>
              </v-col>
              <v-col cols="12">
                <z-text-field placeholder=" " :rules="regras.email" label="Login" v-model="usuario.email" :disabled="isAdmin || isEditing" autocomplete="off"></z-text-field>
              </v-col>
              <v-col cols="12">
                <z-select :items="perfis" label="Perfil" v-model="usuario.perfil_id" :disabled="isAdmin"></z-select>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" v-if="exibirSenhaAtual">
                <z-text-field placeholder=" " :error-messages="erros.senha_atual" :rules1="regras.senha_atual" :type="exibir_senha_atual ? 'text' : 'password'" label="Senha Atual" v-model="usuario.senha_atual" @click:append="exibir_senha_atual = !exibir_senha_atual" :append-icon="exibir_senha_atual ? 'mdi-eye-off' : 'mdi-eye'" autocomplete="off"></z-text-field>
              </v-col>
              <v-col cols="12">
                <z-text-field placeholder=" " :error-messages="erros.senha" :rules1="regras.senha" :type="exibir_senha1 ? 'text' : 'password'" label="Senha" v-model="senha" @click:append="exibir_senha1 = !exibir_senha1" :append-icon="exibir_senha1 ? 'mdi-eye-off' : 'mdi-eye'" autocomplete="off"></z-text-field>
              </v-col>
              <v-col cols="12">
                <z-text-field placeholder=" " :error-messages="erros.confirmar_senha" :rules1="regras.confirmar_senha" :type="exibir_senha2 ? 'text' : 'password'" label="Confirmar Senha" v-model="confirmar_senha" @click:append="exibir_senha2 = !exibir_senha2" :append-icon="exibir_senha2 ? 'mdi-eye-off' : 'mdi-eye'" autocomplete="off"></z-text-field>
              </v-col>
            </v-row>
        </template>

        <template v-slot:actions_left>
          <v-switch v-if="isEditing" class="my-0 shrink" color="primary" label="Ativo" v-model="usuario.ativo" hide-details :disabled="isAdmin"></v-switch>
        </template>
        <template v-slot:actions_right>
          <v-btn text  @click="fechar" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" @click="confirmar" :loading="salvando">Salvar</v-btn>
        </template>

    </z-dialogo>
  </v-form>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { clone } from '@/app/helpers/utils';

export default {
    name: 'DialogoUsuariosForm',
    data() {
      return {
        salvando: false,
        tab: '',
        senha: '',
        confirmar_senha: '',
        exibir_senha_atual: false,
        exibir_senha1: false,
        exibir_senha2: false,
        form_valido: false,
        erros: {
          senha_atual: '',
          senha: '',
          confirmar_senha: ''
        },
        regras: {
          nome: [v => !!v || 'O nome do usuário precisa ser preenchido.'],
          email: [v => !!v || 'O login do usuário precisa ser preenchido.'],
          senha_atual: [v => !!v || 'A senha atual precisa se informada.'],
          senha: [v => !!v || 'A senha precisa ser preenchida.', v => (v && v.length >= 3) || 'A senha precisa ter o mínimo de 3 dígitos'],
          confirmar_senha: [
            v => !!v || 'Confirmar senha precisa ser preenchida.', 
            v => (v && v.length >= 3) || 'Confirmar senha precisa ter o mínimo de 3 dígitos',
            v => v == this.senha || 'As senhas não conferem'
          ]
        }
      };
    },
    
    computed: {
      perfis() {
        return this.$store.getters['listas/perfis']
      },

      usuario() {
        return this.$store.state.usuarios.usuario;
      },

      isAdmin(){
        if (this.usuario.name == 'Administrador') {
          return true
        }
        return false;
      },

      isEditing() {
        return this.usuario.id != null;
      },

      titulo() {
        return 'Usuário';
      },

      exibirSenhaAtual() {
        return this.isEditing && this.usuario.name == 'Administrador';
      }
    },

    methods: {
      ...mapMutations(["setTitulo"]),
      ...mapMutations("usuarios", ["UNSET_USUARIO"]),
      ...mapActions("usuarios", ["abrir", "atualizar", "salvar"]),

      abrir() {
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.$refs.dialogo.fechar();
        this.UNSET_USUARIO();
      },

      async confirmar() {
        if (this.$refs.form.validate()) {
          try {
            this.salvando = true;
            
            if (!this.validarSenha()) return false;

            this.usuario.password = this.confirmar_senha;
            if (this.usuario.id) {
                await this.atualizar(this.usuario);
            } else {
                await this.salvar(this.usuario);
            }
            
            this.$eventbus.notificar("Usuário salvo com sucesso");
            this.fechar();
          } catch(error) {
            this.$eventbus.notificarErro('Não foi possível salvar este Usuário. Verifique os dados do usuario e tente novamente.', error);
          } finally {
              this.salvando = false;
          }
        }
      },

      validarSenha() {
        this.erros = {
          senha_atual: '',
          senha: '',
          confirmar_senha: ''
        }

        if (this.confirmar_senha || !this.isEditing) {
          if (this.usuario.name == 'Administrador') {
            if (!this.usuario.senha_atual) {
              this.erros.senha_atual = 'A senha atual precisa ser informada.';
              return false;
            }
          }

          if (!this.senha) {
            this.erros.senha = 'A senha precisa ser informada.';
            return false;
          }

          if (!this.confirmar_senha) {
            this.erros.confirmar_senha = 'A confirmação da senha precisa ser informada.';
            return false;
          }

          if (this.confirmar_senha != this.senha) {
            this.erros.confirmar_senha = 'A confirmação da senha não está correta.';
            return false;
          }
        }

        return true;
      }
    },

   

};
</script>

<style scoped>
/* .container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
} */
</style>
