import createCrudApi from "@/app/services/baseApi";
const contasReceberApi = createCrudApi('contasreceber');

const { axios } = contasReceberApi;

// contasReceberApi.adicionarParceiro = function(params) {
//   return axios.post('empresas/parceiros', params).then((response) => response.data);
// }

// contasReceberApi.removerParceiro = function(params) {
//   return axios.delete('empresas/parceiros', { params: params }).then((response) => response.data);
// }

contasReceberApi.darBaixa = function(contareceber) {
  return axios.post(`contasreceber/${contareceber.id}/darbaixa`, contareceber).then((response) => response.data);
}

contasReceberApi.cancelarRecebimento = function(contareceber) {
  return axios.delete(`contasreceber/${contareceber.id}/cancelarrecebimento`).then((response) => response.data);
}

export default contasReceberApi;
