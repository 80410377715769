<template>
    
  <v-toolbar-items>

    <template v-for="(menu) in permissoes">

      <v-btn v-if="!menu.name" icon :key="menu.name" @click="$router.push(menu.to)">
        <v-icon>{{ menu.icon }}</v-icon>
      </v-btn>

      <v-menu v-if="menu.visible" bottom offset-y transition="slide-y-transition" :key="menu.name">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text tile v-bind="attrs" v-on="on" class="text-capitalize font-weight-regular">
            <v-icon v-if="menu.icon" class="mr-1">{{menu.icon}}</v-icon>
            {{menu.name}}
            <!-- <v-icon right>mdi-chevron-down</v-icon> -->
          </v-btn>
        </template>

        <v-list nav dense v-if="menu.children">

          <v-subheader class="text-uppercase text--primary font-weight-black">{{menu.name}}</v-subheader>

          <v-list-item-group v-model="item" color="primary">

            <template v-for="(item, i) in menu.children">
              <v-divider v-if="item.name == 'divider'" :key="`${i}a`"></v-divider>

              <v-list-item v-else :value="item.name" :key="i" :to="item.to" :disabled="!$auth.temPermissao(item.permissao)">
                <v-list-item-icon v-if="item.icon">
                  <v-icon :color="item.icon_color">{{item.icon}}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

          </v-list-item-group>
        </v-list>
      </v-menu>

    </template>

  </v-toolbar-items>

</template>

<script>

  // import menu from "./menu";
  import permissoes from "@/pages/sistema/usuarios/permissoes";
  export default {
    name: 'menu-principal',
    
    data: () => ({
      item: null,
      // menus: menu
    }),

    computed: {
      permissoes() {
        return permissoes[0].children; 
      }
    },

    methods: {
      sair() {
        //
      }
    },
  }
</script>

<style scoped>

</style>