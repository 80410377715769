<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="dates" persistent width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field ref="input" v-model="fullDate" @click:append="modal = true" append-icon="mdi-calendar" :readonly="true" v-bind="$attrs" v-on="on" @click:clear="clear"></v-text-field>
    </template>
    <v-date-picker v-model="dates" scrollable range color="primary">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
      <v-btn text color="primary" @click="salvar">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import form_mixin from "./form_mixin";

  export default {
    mixins: [form_mixin],
    props: {
      value: {
        type: Array,
        default: () => []
      },
    },

    data() {
      return {
        fullDate: '',
        modal: false,
      }
    },

    methods: {
      salvar() {
        this.$refs.dialog.save(this.dates);
      },

      formatDates(dates) {
        if (dates.length > 1) {
          let newDates = dates.map(date => {
            return (new Date(date)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
          });
          return 'De ' + newDates.join(' a ')
        }
        return '';
      },

      clear() {
        this.dates = [];
      }
    },

    computed: {
      dates: {
        get () {
          this.fullDate = this.formatDates(this.value);
          return this.value;
        },
        set (val) {
          this.$emit('input', val);
        }
      },
    },
  }
</script>

<style scoped>

</style>