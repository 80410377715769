import listasApi from "@/app/services/listasApi";

export default {
  namespaced: true,
  state: {
    empresas: [],
    parceiros: [],
    perfis: [],
  },

  getters: {
    empresas(state) {
      return state.empresas.map((empresa) => {
        return {
          descricao: empresa.id + " - " +empresa.razao,
          id: empresa.id,
          regime_tributario: empresa.regime_tributario.charAt(0).toUpperCase() + empresa.regime_tributario.slice(1),
          percentual_desconto: empresa.percentual_desconto
        };
      });
    },

    parceiros(state) {
      return state.parceiros.map((parceiro) => {
        return {
          descricao: parceiro.id + " - " + parceiro.nome,
          id: parceiro.id,
          percentual_desconto: parceiro.percentual_desconto
        };
      });
    },

    perfis(state) {
      return state.perfis.map((perfil) => {
        return {
          text: perfil.descricao,
          value: perfil.id,
        };
      });
    },
  },

  mutations: {
    SET_EMPRESAS(state, empresas) {
      state.empresas = empresas;
    },

    SET_PARCEIROS(state, parceiros) {
      state.parceiros = parceiros;
    },

    SET_PERFIS(state, perfis) {
      state.perfis = perfis;
    },
  },

  actions: {
    async todas({ commit, dispatch }) {
      dispatch("empreas");
      dispatch("parceiros");
      dispatch("perfis");
    },

    async empresas({ commit }) {
      let empresas = await listasApi.empresas();
      commit("SET_EMPRESAS", empresas);
    },

    async parceiros({ commit }) {
      let parceiros = await listasApi.parceiros();
      commit("SET_PARCEIROS", parceiros);
    },

    async perfis({ commit }) {
      let perfis = await listasApi.perfis();
      commit("SET_PERFIS", perfis);
    },
  },
};
