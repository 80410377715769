import { defineStore } from 'pinia'

export const useCounterStore = defineStore('counter', {
  state: () => ({
    count: 0,
    counter: 0,
    blah: {},
    person: {
      name: 'John',
      lastname: 'Doe'
    }
  }),
  // could also be defined as
  // state: () => ({ count: 0 })

  getters: {
    double() {
      return this.count * 2;
    }
  },

  actions: {
    increment() {
      this.count++;
    },

    async getBlah() {
      let response = await fetch('https://api.agify.io/?name=blah').then(response => response.json());
      this.blah = response;
      console.log({...response})
      return response;
    },

    setBlah(blah) {
      this.blah = blah;
    },

    changeName(name) {
      this.person.name = name;
      return name;
    }
  },
})