import * as icon from "../../../app/icons";

export default [
  {
    id: "todas",
    name: "Acesso Total",
    icon: "mdi-check-all",
    children: [
      {
        id: "cadastros",
        name: "Cadastros",
        icon: icon.CADASTROS,
        visible: true,
        children: [
          { 
            id: "empresas", 
            name: "Empresas", 
            icon: icon.EMPRESAS, 
            to: { name: 'EmpresasLista' }, 
            permissao: "empresas_acessar",
            disabled: false, 
            children: [
              { id: "empresas_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "empresas_adicionar", name: "Adicionar", icon: icon.ADICIONAR },
              { id: "empresas_editar", name: "Editar", icon: icon.EDITAR },
              { id: "empresas_excluir", name: "Excluir", icon: icon.EXCLUIR },
              { id: "empresas_imprimir", name: "Imprimir", icon: icon.IMPRIMIR, },
            ],
          },
          {
            id: "parceiros",
            name: "Parceiros",
            icon: icon.PARCEIROS,
            to: {name: 'ParceirosLista'}, 
            permissao: "parceiros_acessar",
            disabled: false,
            children: [
              { id: "parceiros_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "parceiros_adicionar", name: "Adicionar", icon: icon.ADICIONAR, },
              { id: "parceiros_editar", name: "Editar", icon: icon.EDITAR },
              { id: "parceiros_excluir", name: "Excluir", icon: icon.EXCLUIR, },
            ],
          }
        ],
      },
      { 
        id: "movimentacoes", 
        name: "Movimentações", 
        icon: icon.MOVIMENTACOES,
        visible: true,
        children: [
          { 
            id: "guias", 
            name: "Controle de Guias Lançadas", 
            icon: icon.GUIAS, 
            to: {name: 'GuiasLista'},
            permissao: "guias_acessar",
            disabled: false,
            children: [
              { id: "guias_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "guias_adicionar", name: "Adicionar", icon: icon.ADICIONAR, },
              { id: "guias_editar", name: "Editar", icon: icon.EDITAR, },
              { id: "guias_excluir", name: "Excluir", icon: icon.EXCLUIR, },
            ],
          },
          { 
            id: "contasreceber", 
            name: "Contas a Receber - Empresas", 
            icon: icon.CONTAS_RECEBER,
            icon_color: 'green',
            to: { name: 'ContasReceberLista' },
            permissao: "contas_receber_acessar",
            disabled: false,
            children: [
              { id: "contas_receber_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "contas_receber_adicionar", name: "Adicionar", icon: icon.ADICIONAR, },
              { id: "contas_receber_editar", name: "Editar", icon: icon.EDITAR, },
              { id: "contas_receber_excluir", name: "Excluir", icon: icon.EXCLUIR, },
            ],
          },
          { 
            id: "contaspagar", 
            name: "Contas a Pagar - Parceiros", 
            icon: icon.CONTAS_PAGAR,
            icon_color: 'red', 
            to: { name: 'ContasPagarLista' },
            permissao: "contaspagar_acessar",
            disabled: false,
            children: [
              { id: "contaspagar_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "contaspagar_adicionar", name: "Adicionar", icon: icon.ADICIONAR, },
              { id: "contaspagar_editar", name: "Editar", icon: icon.EDITAR, },
              { id: "contaspagar_excluir", name: "Excluir", icon: icon.EXCLUIR, },
            ],
          }
        ],
      },
      { 
        id: "relatorios", 
        name: "Relatórios", 
        icon: icon.RELATORIOS,
        visible: true,
        children: [
          // { 
          //   id: "percentuais_parceiros", 
          //   name: "Relatório Percentuais Parceiros", 
          //   icon: icon.RELATORIOS, 
          //   to: { name: 'PercentuaisParceiros' },
          //   permissao: "percentuais_parceiros_acessar",
          //   disabled: false,
          //   children: [
          //     { id: "percentuais_parceiros_acessar", name: "Acessar", icon: icon.ACESSAR, },
          //     { id: "percentuais_parceiros_imprimir", name: "Imprimir", icon: icon.IMPRIMIR, },
          //   ],
          // },
          { 
            id: "guias_lancadas", 
            name: "Relatório de Guias Lançadas", 
            icon: icon.RELATORIOS, 
            to: { name: 'GuiasLancadas' },
            permissao: "guias_lancadas_acessar",
            disabled: false,
            children: [
              { id: "guias_lancadas_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "guias_lancadas_imprimir", name: "Imprimir", icon: icon.IMPRIMIR, },
            ],
          },
          { 
            id: "contas_pagar_parceiros", 
            name: "Relatório Contas a Pagar - Parceiros", 
            icon: icon.RELATORIOS, 
            to: { name: 'ContasPagarParceiros' },
            permissao: "contas_pagar_parceiros_acessar",
            disabled: false,
            children: [
              { id: "contas_pagar_parceiros_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "contas_pagar_parceiros_imprimir", name: "Imprimir", icon: icon.IMPRIMIR, },
            ],
          }
        ],
      },
      { 
        id: "sistema", 
        name: "Sistema", 
        icon: icon.SISTEMA,
        visible: true,
        children: [
          { 
            id: "usuarios", 
            name: "Usuários", 
            icon: icon.USUARIOS, 
            to: {name: 'UsuariosLista'},
            permissao: "usuarios_acessar",
            disabled: false,
            children: [
              { id: "usuarios_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "usuarios_adicionar", name: "Adicionar", icon: icon.ADICIONAR },
              { id: "usuarios_editar", name: "Editar", icon: icon.EDITAR },
              { id: "usuarios_excluir", name: "Excluir", icon: icon.EXCLUIR },
            ],
          },
          { 
            id: "permissoes_acessar", 
            name: "Permissões", 
            icon: icon.PERMISSOES, 
            to: {name: 'Permissoes'},
            permissao: "permissoes_acessar",
            disabled: false, 
          },
          // { id: 'configuracoes_acessar', name: 'Configurações' },
        ],
      },
    ],
  },
];
