import ContasPagarParceiros from '@/pages/relatorios/ContasPagarParceiros'
import GuiasLancadas from '@/pages/relatorios/GuiasLancadas'

export default [
  {
    path: '/relatorios/contaspagarparceiros',
    name: 'ContasPagarParceiros',
    component: ContasPagarParceiros,
    meta: { permissao: 'contas_pagar_parceiros_acessar' }
  },
  {
    path: '/relatorios/guiaslancadas',
    name: 'GuiasLancadas',
    component: GuiasLancadas,
    meta: { permissao: 'guias_emitidas_acessar' }
  }
];
